import React,{useState,useEffect} from 'react'
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import LangagueChange from './LangagueChange';

function Aboutus() {
  const [isLoading, setIsLoading] = useState(true);

  // Simulate loading for demonstration purposes
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate a 2-second loading time
  }, []);
  const navigate=useNavigate();
  return (
    <div>
    
    <div className=' bg-bgsecond min-h-[100vh] font-Montserrat text-myblue  lg:pt-10 md:pt-4 pt-3 '>
      <header className='flex justify-between lg:px-32 md:px-16 px-8'>
    <i onClick={()=>navigate('/en')} className='cursor-pointer'>
  <img src={require('../img/Group.png')} className='lg:w-[4rem] md:w-[2.5rem] w-[1.5rem]' alt='icon'/>
</i>
<span className='relative'>
<i class="fa-solid fa-bars lg:text-[3rem] md:text-[2rem] text-[1rem] cursor-pointer  text-myblue" onClick={()=>{
document.querySelector('.fa-bars').style.display='none';  
document.querySelector('.fa-x').style.display='block';  
document.querySelector('.nav').style.display='flex';  
}}></i>
<i class="fa-solid fa-x lg:text-[2.4rem] md:text-[1.7rem] text-[0.8rem] hidden cursor-pointer  text-myblue" onClick={()=>{
document.querySelector('.fa-bars').style.display='block';  
document.querySelector('.fa-x').style.display='none';  
document.querySelector('.nav').style.display='none';  
}}></i>
<ul className='bg-bgsecond nav hidden z-1000 text-myblue lists  flex-col rounded-md gap-2 absolute shadow-md lg:text-[1.4rem] md:text-[0.8rem] text-[0.6rem] lg:right-[2rem] right-[1.5rem] lg:w-[12vw] w-[30vw]'>
  <li className=' px-[5px] py-[1.5px] font-semibold border-b'><a href='/en' className='w-full block'>home</a></li>
  <li className=' px-[5px] py-[1.5px]  font-semibold border-b cursor-pointer' ><a href='/en/privacypolicy' className='w-full block'>privacy policy</a></li>
</ul>
</span></header>
<div className=' lg:mx-32 md:mx-16 mx-8 lg:mt-10 md:mt-[20px] mt-[10px] bg-myblue text-white rounded-md lg:text-[1.2rem] md:text-[1rem] text-[0.8rem] lg:px-4 md:px-2 px-[16px] w-fit p-[3px]'>about us</div>
<section className='lg:px-32 md:px-16 px-8 flex flex-col lg:gap-8 md:gap-4 gap-[8px]  lg:my-10 my-[10px]'>
    <p className='lg:text-[1.4rem] md:text-[1rem] text-[0.45rem]'>Welcome to <b>MY DRIVE CAR</b>, where the road meets your dreams. With a decade of experience in the industry, we're not just a car rental agency; we're your trusted travel companion.</p>
    <img className='lg:h-[60vh] md:h-[45vh] h-[20vh]' src={require('../img/IMG_20230913_020830_896.png')}/>
    <div className='flex items-center lg:gap-4 md:gap-2 gap-[10px]'>
        <p className='w-[48%] lg:text-[1.4rem] md:text-[1rem] text-[0.45rem]  lg:leading-[3rem] items-center'>Our journey was initiated by our visionary CEO,<b> Younes</b>. His passion for cars, coupled with more than <b>ten years of industry expertise</b>, has driven our agency's mission to redefine the way you experience travel. We're not merely about renting cars; we're about unlocking the possibilities of the open road.</p>
    <img className='w-[48%] lg:h-[50vh] md:h-[45vh] h-[15vh]' src={require('../img/IMG_20230913_020828_855.png')}/></div>
    <div className='flex items-center lg:gap-4 md:gap-2 gap-[10px]'>
        <img className='w-[48%] lg:h-[50vh] md:h-[45vh] h-[15vh]' src={require('../img/IMG_20230913_020829_570.png')}/>
        <p className='w-[48%] lg:text-[1.4rem] md:text-[1rem] text-[0.45rem]  lg:leading-[3rem] items-center'>Meet <b>Younes</b>, our CEO, and the driving force behind <b> MY DRIVE CAR</b>. With a profound commitment to excellence and a wealth of experience that spans over a decade, he has steered our agency toward a path of innovation, reliability, and unparalleled customer satisfaction.</p>
        </div>
    <div className='flex items-center lg:gap-4 md:gap-2 gap-[10px]'>
    <p className='w-[48%] lg:text-[1.4rem] md:text-[1rem] text-[0.45rem]  lg:leading-[3rem] items-center'>Over the years, we've grown and evolved, but our core values remain unchanged. We believe that every journey should be memorable, every traveler should feel safe, and every rental should be hassle-free.</p>
    <img className='w-[48%] lg:h-[50vh] md:h-[45vh] h-[15vh]' src={require('../img/5fadd6e3d90975df2dbc7060b4b0acdb.jpeg')}/></div>
    <div className='flex justify-center gap-[4%] lg:text-[1.5rem] md:text-[0.8rem] text-[0.65rem]'><a href='https://www.facebook.com/MDC.rentalcars' target='_blank' className='fa-brands font-light fa-facebook'></a><a href='http://m.me/MDC.rentalcars' target='_blank' className='fa-brands font-light fa-facebook-messenger'></a><a href='https://www.instagram.com/my_drive_car/' target='_blank' className="fa-brands fa-instagram"></a><a target='_blank' href='https://wa.me/+212666703720' className="fa-brands fa-whatsapp"></a></div>
</section>
<Footer px={32}/>
    </div></div>
  )
}

export default Aboutus
