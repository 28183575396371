import React, { useEffect, useState } from 'react'

function Footer({px}) {
  const [en,setEn]=useState('');
  const [sp,setSp]=useState('');
  useEffect(()=>{
   setEn(localStorage.getItem('en'));
   setSp(localStorage.getItem('sp'))
  },[])
  return (
    en?
    <footer>
      
     <div className={`bg-[#F2F6FF] text-[#A7A7A7] whitespace-nowrap lg:text-[1.4rem] md:text-[0.8rem] text-[0.45rem] font-[400] flex gap-[30%] lg:px-${px} md:px-${px/2} px-${px/4}`}>
        <a href='/aboutus'>About us</a>
        <a href='/privacypolicy'>Privacy Policy</a>
        </div> 
     <div className='py-2 flex justify-center bg-white text-[#B8CAFF] lg:text-[1rem] md:text-[0.8rem] text-[0.45rem] font-[400]'>
   <p>2023, Mdcrentalcars Made with love by <a rel="nofollow noopener noreferrer" href='https://thedgmarketing.com' target='_blank' className='text-[#B8CAFF] underline'>Dgmarketing</a></p>
    </div> 
    </footer>
    :sp?(<footer>
      <div className={`bg-[#F2F6FF] text-[#A7A7A7] whitespace-nowrap lg:text-[1.4rem] md:text-[0.8rem] text-[0.45rem] font-[400] flex gap-[30%] lg:px-${px} md:px-${px/2} px-${px/4}`}>
         <a href='/aboutus'>Sobre nosotros</a>
         <a href='/privacypolicy'>Política de privacidad</a>
         </div> 
      <div className='py-2 flex justify-center bg-white text-[#B8CAFF] lg:text-[1rem] md:text-[0.8rem] text-[0.45rem] font-[400]'>
    <p>2023, Mdcrentalcars Hecho con amor por <a rel="nofollow noopener noreferrer" href='https://thedgmarketing.com' target='_blank' className='text-[#B8CAFF] underline'>Dgmarketing</a></p>
     </div> 
     </footer>)
 :(<footer>
    <div className={`bg-[#F2F6FF] text-[#A7A7A7] whitespace-nowrap lg:text-[1.4rem] md:text-[0.8rem] text-[0.45rem] font-[400] flex gap-[30%] lg:px-${px} md:px-${px/2} px-${px/4}`}>
       <a href='/aboutus'>À propos de nous</a>
       <a href='/privacypolicy'>Politique de confidentialité</a>
       </div> 
    <div className='py-2 flex justify-center bg-white text-[#B8CAFF] lg:text-[1rem] md:text-[0.8rem] text-[0.45rem] font-[400]'>
  <p>2023, Mdcrentalcars Fabriqué avec amour par <a rel="nofollow noopener noreferrer" href='https://thedgmarketing.com' target='_blank' className='text-[#B8CAFF] underline'>Dgmarketing</a></p>
   </div> 
   </footer>)
)
  
}

export default Footer
