import React, { useEffect, useState } from "react";
import french from '../img/french.png';
import english from '../img/english.png';
import spanich from '../img/spanish.png'
function LangagueChange() {
  const [lan, setLan] = useState("");

  useEffect(() => {
    // Get the path from the URL
    const path = window.location.pathname;

    // Split the path by '/'
    const pathSegments = path.split("/");

    // Get the last segment which should be the language code
    const languageCode = pathSegments[pathSegments.length - 1];

    // Update the state with the extracted language code
    setLan(languageCode);
  }, []);
  return (
    <div className="absolute flex z-20 items-center gap-4 left-[50%] lg:text-[2rem] md:text-[1.3rem] text-[0.9rem]  translate-x-[-50%]  rounded-b-md lg:p-3 md:p-2 p-1 lg:top-[3vh] top-[1vh]">
      <div className={`flex lans justify-center items-center gap-[12px]`}>
        <a
          aria-label="Switch to French"
          href="/"
          className={`p-[5px] flex ${
            !(lan === "en" || lan === "sp")
              ? "bg-red-500 text-white"
              : "bg-white text-black"
          }   font-bold cursor-pointer rounded-md`}
        >
          <img alt='iconfrench' src={french} className=" lg:w-[2rem] w-[0.9rem] lg:h-[2rem] h-[0.9rem]"></img>
        </a>
        <a
          href="/en"
          className={`p-[5px] flex cursor-pointer rounded-md ${
            lan === "en" ? "bg-red-500 text-white" : "bg-white text-black"
          } `}
          aria-label="Switch to English"
        >
          <img alt='iconenglish' src={english} className=" lg:w-[2rem] w-[0.9rem] lg:h-[2rem] h-[0.9rem]"></img>
        </a>
        <a
          aria-label="Switch to Spanish"
          href="/sp"
          className={`p-[5px]  cursor-pointer rounded-md flex ${
            lan === "sp" ? "bg-red-500 text-white" : "bg-white text-black"
          } `}
        >
          <img alt='iconspanich' src={spanich} className=" lg:w-[2rem] w-[0.9rem] lg:h-[2rem] h-[0.9rem]"></img>
        </a>
      </div>
    </div>
  );
}

export default LangagueChange;
