import DriveCaar from "./components/DriveCaarEn";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthContext } from "./config/authContext";
import Admin from "./components/Admin";
import Aboutus from "./components/Aboutus";
import Privacypolicy from "./components/Privacypolicy";
import Blog from "./components/Blog";
import Car from "./components/Car";
import DriveCaarsp from "./components/DriveCaarSp";
import DriveCaarfr from "./components/DriveCaarFn";
import Carsp from "./components/Carsp";
import Carfr from "./components/Carfr";
import Aboutusfr from "./components/Aboutusfr";
import Aboutussp from "./components/Aboutussp";
import Privacypolicysp from "./components/Privacypolicysp";
import Privacypolicyfr from "./components/Privacypolicyfr";
import Blogsp from "./components/Blogsp";
import Blogfr from "./components/Blogfr";


function App() {
  const [en,setEn]=useState('');
  const [sp,setSp]=useState('');
  useEffect(()=>{
   setEn(localStorage.getItem('en'));
   setSp(localStorage.getItem('sp'))
  },[])
  const [authState,setAuthState]=useState({name:"",status:false,id:0});
  return (
    <AuthContext.Provider value={{authState,setAuthState}}>
    <BrowserRouter>
      <Routes>
        <Route  path='/en' element={<DriveCaar/>}/>
        <Route  path='/sp' element={<DriveCaarsp/>}/>
        <Route  path='/' element={<DriveCaarfr/>}/>
        <Route  path='/en/car/:title/:id' element={<Car/>}/>
        <Route  path='/sp/car/:title/:id' element={<Carsp/>}/>
        <Route  path='/car/:title/:id' element={<Carfr/>}/>
        <Route  path='/admin' element={<Admin/>}/>
        <Route  path='/en/aboutus'  element={<Aboutus/>}/>
        <Route  path='/sp/aboutus'  element={<Aboutussp/>}/>
        <Route  path='/aboutus'  element={<Aboutusfr/>}/>
        <Route  path='/en/privacypolicy' element={<Privacypolicy/>}/>
        <Route  path='/sp/privacypolicy' element={<Privacypolicysp/>}/>
        <Route  path='/privacypolicy' element={<Privacypolicyfr/>}/>
        <Route  path='/en/blog/:tit/:id' element={<Blog/>}/>
        <Route  path='/sp/blog/:tit/:id' element={<Blogsp/>}/>
        <Route  path='/blog/:tit/:id' element={<Blogfr/>}/>
      </Routes>
    </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
