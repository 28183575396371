import React,{useState,useEffect} from 'react'
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import LangagueChange from './LangagueChange';

function Privacypolicy() {
  const navigate=useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate a 2-second loading time
  }, []);
  return (
    <div>

   
    <div className=' bg-bgsecond min-h-[100vh] font-Montserrat text-myblue  lg:pt-10 md:pt-4 pt-3 '>
    <header className='flex justify-between lg:px-32 md:px-16 px-8'>
  <i onClick={()=>navigate('/en')} className='cursor-pointer'>
<img src={require('../img/Group.png')} className='lg:w-[4rem] md:w-[2.5rem] w-[1.5rem]' alt='icon'/>
</i>
<span className='relative'>
<i class="fa-solid fa-bars lg:text-[3rem] md:text-[2rem] text-[1rem] cursor-pointer  text-myblue" onClick={()=>{
document.querySelector('.fa-bars').style.display='none';  
document.querySelector('.fa-x').style.display='block';  
document.querySelector('.nav').style.display='flex';  
}}></i>
<i class="fa-solid fa-x lg:text-[2.4rem] md:text-[1.7rem] text-[0.8rem] hidden cursor-pointer  text-myblue" onClick={()=>{
document.querySelector('.fa-bars').style.display='block';  
document.querySelector('.fa-x').style.display='none';  
document.querySelector('.nav').style.display='none';  
}}></i>
<ul className='bg-bgsecond nav hidden z-1000 text-myblue lists  flex-col rounded-md gap-2 absolute shadow-md lg:text-[1.4rem] md:text-[0.8rem] text-[0.6rem] lg:right-[2rem] right-[1.5rem] lg:w-[12vw] w-[30vw]'>
<li className=' px-[5px] py-[1.5px] font-semibold border-b'><a href='/en' className='w-full block'>home</a></li>
<li className=' px-[5px] py-[1.5px]  font-semibold border-b cursor-pointer' ><a href='/en/aboutus' className='w-full block'>about us</a></li>
</ul>
</span></header>
<div className=' lg:mx-32 md:mx-16 mx-8 lg:mt-10 md:mt-[20px] mt-[10px] bg-myblue text-white rounded-md lg:text-[1.2rem] md:text-[1rem] text-[0.8rem] lg:px-4 md:px-2 px-[16px] w-fit p-[3px]'>privacy policy</div>
<section className='lg:px-32 md:px-16 px-8 flex flex-col lg:gap-8 md:gap-4 gap-[8px]  lg:my-10 my-[10px]'>
  <ul className='list-disc	lg:text-[1.2rem] md:text-[0.8rem] text-[0.6rem]'>
    <li>
        <h1 className='font-semibold'>Collection of Personal Information</h1>
        <p>We collect personal information from you when you make a reservation, open an account, or use our services. This information may include your name, address, phone number, email address, driver's license number, credit card information, and other identifying information. We may also collect information about your rental history, including the type of vehicle you rented, the dates and times of your rental, and your driving information.</p>
    </li>
    <li>
        <h1 className='font-semibold'>Use of Personal Information</h1>
        <p>We use your personal information to provide you with our services, including processing your reservations, billing you for your rentals, and providing you with customer service. We may also use your information to send you marketing communications, such as offers and discounts. We will not sell or rent your personal information to third parties.</p>
    </li>
    <li>
        <h1 className='font-semibold'>Sharing of Personal Information</h1>
<p>We may share your personal information with our affiliated companies, franchisees, and independent contractors who provide services to us. We may also share your information with third-party vendors who help us operate our business, such as credit card processors, insurance companies, and roadside assistance providers. We will only share your information with these third parties as necessary to provide you with our services or to comply with legal requirements.</p>    </li>
    <li>
        <h1 className='font-semibold'>Your Rights</h1>
<p>You have the right to access, correct, or delete your personal information. You also have the right to object to the processing of your personal information. You can exercise these rights by contacting us at the address or phone number provided below.</p>    </li>
    
    <li>
        <h1 className='font-semibold'>Data Security</h1>
        <p>We take appropriate technical and organizational measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. We use a variety of security technologies and procedures to help protect your information, such as firewalls, data encryption, and access controls.</p>
   </li>
    <li>
        <h1 className='font-semibold'>Changes to this Privacy Policy</h1>
<p>
We may update this Privacy Policy from time to time. We will post any changes on this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your personal information.</p></li>
    <li>        
        <h1 className='font-semibold'>Contact Us</h1>
<p>If you have any questions about this Privacy Policy, please contact us at:</p>
<div className='flex flex-col lg:gap-2  gap-1 lg:pl-4 md:pl-2 pl-[10px]'>
        <p className='uppercase'>My drive car</p>
        <a href='mailto:Contact@mdcrentalcars.com' rel="nofollow noopener noreferrer" className='underline' target='_blank'>Contact@mdcrentalcars.com</a>
        <a href='https://goo.gl/maps/MNhaT5xYumiF6fzR8'  rel="nofollow noopener noreferrer"className='underline' target='_blank'>Marrakech-Morocco</a>
        <a href='tel:+212665859587' className='underline' rel="nofollow noopener noreferrer" target='_blank'>+212665859587</a>
        </div>
</li>
  </ul>
</section>
<Footer px={32}/>
  </div></div>
)
  
}

export default Privacypolicy
