import React,{useState,useEffect} from 'react'
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import LangagueChange from './LangagueChange';

function Privacypolicysp() {
  const navigate=useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate a 2-second loading time
  }, []);
  return (
    <div>

    
      <div className=' bg-bgsecond min-h-[100vh] font-Montserrat text-myblue  lg:pt-10 md:pt-4 pt-3 '>
        <header className='flex justify-between lg:px-32 md:px-16 px-8'>
          <i onClick={()=>navigate('/sp')} className='cursor-pointer'>
            <img src={require('../img/Group.png')}  alt='icon' className='lg:w-[4rem] md:w-[2.5rem] w-[1.5rem]'/>
          </i>
          <span className='relative'>
            <i class="fa-solid fa-bars lg:text-[3rem] md:text-[2rem] text-[1rem] cursor-pointer  text-myblue" onClick={()=>{
              document.querySelector('.fa-bars').style.display='none';  
              document.querySelector('.fa-x').style.display='block';  
              document.querySelector('.nav').style.display='flex';  
            }}></i>
            <i class="fa-solid fa-x lg:text-[2.4rem] md:text-[1.7rem] text-[0.8rem] hidden cursor-pointer  text-myblue" onClick={()=>{
              document.querySelector('.fa-bars').style.display='block';  
              document.querySelector('.fa-x').style.display='none';  
              document.querySelector('.nav').style.display='none';  
            }}></i>
            <ul className='bg-bgsecond nav hidden z-1000 text-myblue lists  flex-col rounded-md gap-2 absolute shadow-md lg:text-[1.4rem] md:text-[0.8rem] text-[0.6rem] lg:right-[2rem] right-[1.5rem] lg:w-[12vw] w-[30vw]'>
              <li className=' px-[5px] py-[1.5px] font-semibold border-b'><a href='/sp' className='w-full block'>inicio</a></li>
              <li className=' px-[5px] py-[1.5px]  font-semibold border-b cursor-pointer' ><a href='/sp/aboutus' className='w-full block'>sobre nosotros</a></li>
            </ul>
          </span>
        </header>
        <div className=' lg:mx-32 md:mx-16 mx-8 lg:mt-10 md:mt-[20px] mt-[10px] bg-myblue text-white rounded-md lg:text-[1.2rem] md:text-[1rem] text-[0.8rem] lg:px-4 md:px-2 px-[16px] w-fit p-[3px]'>política de privacidad</div>
        <section className='lg:px-32 md:px-16 px-8 flex flex-col lg:gap-8 md:gap-4 gap-[8px]  lg:my-10 my-[10px]'>
          <ul className='list-disc	lg:text-[1.2rem] md:text-[0.8rem] text-[0.6rem]'>
            <li>
                <h1 className='font-semibold'>Recopilación de información personal</h1>
                <p>Recopilamos información personal de usted cuando realiza una reserva, abre una cuenta o utiliza nuestros servicios. Esta información puede incluir su nombre, dirección, número de teléfono, dirección de correo electrónico, número de licencia de conducir, información de tarjeta de crédito y otra información de identificación. También podemos recopilar información sobre su historial de alquiler, incluido el tipo de vehículo que alquiló, las fechas y horas de su alquiler y su información de conducción.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Uso de la información personal</h1>
                <p>Utilizamos su información personal para proporcionarle nuestros servicios, incluido el procesamiento de sus reservas, la facturación de sus alquileres y la prestación de servicio al cliente. También podemos utilizar su información para enviarle comunicaciones de marketing, como ofertas y descuentos. No venderemos ni alquilaremos su información personal a terceros.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Compartir información personal</h1>
                <p>Podemos compartir su información personal con nuestras empresas afiliadas, franquiciados y contratistas independientes que nos brindan servicios. También podemos compartir su información con proveedores externos que nos ayudan a operar nuestro negocio, como procesadores de tarjetas de crédito, compañías de seguros y proveedores de asistencia en carretera. Solo compartiremos su información con estos terceros en la medida necesaria para proporcionarle nuestros servicios o para cumplir con los requisitos legales.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Tus derechos</h1>
                <p>Tiene derecho a acceder, corregir o eliminar su información personal. También tiene derecho a oponerse al procesamiento de su información personal. Puede ejercer estos derechos poniéndose en contacto con nosotros en la dirección o número de teléfono proporcionados a continuación.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Seguridad de datos</h1>
                <p>Tomamos medidas técnicas y organizativas apropiadas para proteger su información personal contra el acceso no autorizado, el uso, la divulgación, la alteración o la destrucción. Utilizamos una variedad de tecnologías y procedimientos de seguridad para ayudar a proteger su información, como firewalls, cifrado de datos y controles de acceso.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Cambios en esta política de privacidad</h1>
                <p>Podemos actualizar esta política de privacidad de vez en cuando. Publicaremos cualquier cambio en esta página. Le recomendamos que consulte periódicamente esta política de privacidad para mantenerse informado sobre cómo protegemos su información personal.</p>
            </li>
            <li>        
                <h1 className='font-semibold'>Contáctenos</h1>
                <p>Si tiene alguna pregunta sobre esta política de privacidad, contáctenos en:</p>
                <div className='flex flex-col lg:gap-2  gap-1 lg:pl-4 md:pl-2 pl-[10px]'>
                    <p className='uppercase'>Mi coche de conducción</p>
                    <a href='mailto:Contact@mdcrentalcars.com' rel="nofollow noopener noreferrer" className='underline' target='_blank'>Contact@mdcrentalcars.com</a>
                    <a href='https://goo.gl/maps/MNhaT5xYumiF6fzR8' rel="nofollow noopener noreferrer" className='underline' target='_blank'>Marrakech-Marruecos</a>
                    <a href='tel:+212665859587' className='underline' rel="nofollow noopener noreferrer" target='_blank'>+212665859587</a>
                </div>
            </li>
          </ul>
        </section>
        <Footer px={32}/>
      </div>
    
</div>

)
  
}

export default Privacypolicysp
