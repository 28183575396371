import React, { useRef,useEffect,useState, useContext, useLayoutEffect } from 'react'
import './drivecss/DriveCar.css'
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {Formik,Form,Field,ErrorMessage} from "formik"
import * as yup from 'yup'
import { AuthContext } from '../config/authContext';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import Typed from 'typed.js';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import LangagueChange from './LangagueChange';
import MyIcon from './Myicone';
import { data } from './data';
function DriveCaar() {

  const email=useRef(null);
  
   
  
  const sendEmail = () => {
  emailjs.sendForm('service_o27ogwq', 'template_i8xd1xn', email.current, 'JRfMgH3sDc0nDko7r')
  .then((result) => {
      console.log(result.text);
  }, (error) => {
      console.log(error.text);
  });}
    const [I1,setI1]=useState(0);
    const [I2,setI2]=useState(0);
    const [Is,setIs]=useState([{id:1,count:0},{id:2,count:0},{id:3,count:0},{id:4,count:0},{id:5,count:0}]);
    const form=useRef(null);
    const form1=useRef(null);
    const [object1,setObject1]=useState({});
    const [object2,setObject2]=useState({});
    const [id,setId]=useState(0);
    const form2=useRef(null);
    const total=useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const comm=useRef(null);
    const comm1=useRef(null);
    const mess=useRef(null);
    const mess1=useRef(null);
    const ourcars=useRef(null);
    const contact=useRef(null);
    const how=useRef(null);
    const [datacars,setDatacars]=useState(sortCarsByCategory(data));
    const {setAuthState}=useContext(AuthContext);
    const [dayStart,setDayStart]=useState();
    const [dayEnd,setDayEnd]=useState();
    const [radio,setRadio]=useState(0);
    const [obgsheet,setObgsheet]=useState({})
    const [option,setOption]=useState(false)
    console.log(dayStart);
    const navigate=useNavigate();
    function calculateDaysBetweenDates(daystart, dayend) {
        // Split date strings into year, month, and day components
        if(dayEnd&&dayStart){ 
         const [startYear, startMonth, startDay] = daystart.split('-');
        const [endYear, endMonth, endDay] = dayend.split('-');
        
        // Create Date objects from components (months are 0-based in JavaScript Date)
        const startDate = new Date(startYear, startMonth - 1, startDay);
        const endDate = new Date(endYear, endMonth - 1, endDay);
        
        // Calculate the difference in milliseconds
        const dateDifference = endDate - startDate;
         console.log(dateDifference)
        // Convert milliseconds to days
        const numDays = dateDifference / (1000 * 60 * 60 * 24);
        console.log(numDays);
        return Math.floor(numDays); // Round down to get the whole number of days
         } }
    
    
    const [car,setCar]=useState({});
    
      function sortCarsByCategory(carsArray) {
        const sortedByCategory = [];
      
        carsArray.forEach(car => {
          const { category, title, image,id,prix,front } = car;
      
          const existingCategory = sortedByCategory.find(item => item.category === category);
      
          if (existingCategory) {
            existingCategory.cars.push({ title, image,id,prix,front });
          } else {
            sortedByCategory.push({ category, cars: [{ title, image,id,prix,front }] });
          }
        });
      
        return sortedByCategory.sort((a,b)=>a.category.localeCompare(b.category));
      }
      
      
 
    function onmoveLeft(id){
       const I=Is.find(e=>e.id===id);
       const counts=document.querySelectorAll(`#category-${id} .cars .car`);
       console.log(counts.length);
       
       if(parseInt(I.count)<parseInt(counts.length)/2){
        I.count+=1;
        counts.forEach(e=>e.style.transform=`translateX(-${100*I.count}%) `)   
       
      }}
      
      function onmoveRight(id){
       
          const I=Is.find(e=>e.id===id);
          console.log(I.count)
    
          if(I.count>=1){
            I.count-=1;
            const counts=document.querySelectorAll(`#category-${id} .cars .car`);
          
        counts.forEach(e=>e.style.transform=`translateX(-${100*I.count}%)`)    
        console.log(I.count)
       }
       }
       const [swiper, setSwiper] = useState(null);

    const ref=useRef(null);
    const redcar=useRef(null);
    const shadow=useRef(null);
    function mouseOn(){
        document.querySelectorAll('.minicar').forEach(e=>e.style.animation='topbottom 1.5s infinite linear');
        document.querySelectorAll('.minicarcircle').forEach(e=>e.style.animation='widthmore 1.5s infinite linear');
    }
    function mouseLeave(){
        document.querySelectorAll('.minicar').forEach(e=>e.style.animation='none');
        document.querySelectorAll('.minicarcircle').forEach(e=>e.style.animation='none');
    }
    function toScroll() {
      if (parseInt(window.scrollY) >= parseInt(redcar.current.offsetTop)) {
        redcar.current.style.animation = 'movekey 1s ease-in-out';
        shadow.current.style.animation = 'movekey1 1s ease-in-out';
        console.log('yes');
      } else {
          redcar.current.style.animation = 'none';
         shadow.current.style.animation = 'none';
      }
      if(parseInt(window.scrollY) >= parseInt(ourcars.current.offsetTop)){
          document.querySelector('.reserver').classList.add('hidden')
      }else{
          document.querySelector('.reserver').classList.remove('hidden')

      }}
      function toMoveRight(){
        if(I1>=1){
            document.querySelectorAll('.comment').forEach(e=>e.style.transform=`translate(-${(I1-1)*80}%)`)
            setI1(I1-1);
        }
      }
      function toMoveLeft(){
        var comments=document.querySelectorAll('.comment');
        if(I1<comments.length){
            console.log(I1)
            document.querySelectorAll('.comment').forEach(e=>e.style.transform=`translate(-${(I1+1)*80}%)`)
            setI1(I1+1);
        }
      }
      function toMoveRightcart(){
        if(I2>=1){
            document.querySelectorAll('.cart').forEach(e=>e.style.transform=`translate(-${(I2-1)*99}%)`)
            setI2(I2-1);
        }
      }
      function toMoveLeftcart(){
        var carts=document.querySelectorAll('.cart');
        if(I2<carts.length){
            console.log(I2)
            document.querySelectorAll('.cart').forEach(e=>e.style.transform=`translate(-${(I2+1)*99}%)`)
            setI2(I2+1);
        }
      }
    useEffect(() => {
     
      if(isLoading===false){
        const options = {
          strings: ['Find, book, and rental car in Easy step.'],
          typeSpeed: 75,
          backSpeed: 75,
          loop: true,
          showCursor: false, // Show the text cursor
          cursorChar: '|', // Custom cursor character
        };
      
    
        const typed = new Typed('.typed-text', options);
      
        
        toScroll();
        window.addEventListener('scroll', toScroll);
        // Clean up the event listener when the component unmount
        return () => {
            window.removeEventListener('scroll', toScroll);
            typed.destroy(); // Clean up the Typed instance on unmount
          };}
        })
        
      useLayoutEffect(()=>{
        setTimeout(() => {
          setIsLoading(false);
        }, 1400);
        axios.get('https://82.180.136.212/cars').then((res)=>{
          setDatacars(sortCarsByCategory(res.data));
          console.log(res.data);
        })
        axios.get('https://82.180.136.212/users/auth',{headers:{accesstoken:localStorage.getItem('accessToken')}}).then((res)=>{
          if(res.data.error){
              setAuthState({name:"",status:false,id:0});
              console.log(res.data.error);
          }else{
          setAuthState({email:res.data.user.email,id:res.data.user.id,status:true});}})
        },[option,obgsheet,isLoading])
        function addCar(event) {
          comm.current.style.display='none';
            mess.current.style.display='flex';
          const form=comm.current;
          const formData = new FormData(form);
         console.log(formData);
          axios.post("https://82.180.136.212/cars", formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              accesstoken: localStorage.getItem('accessToken')
            },
          })
            .then((res) => {
            })
            .catch((error) => {
              console.error(error);
            });
            event.preventDefault();
          }
          function updateCar(event) {
            const form1=comm1.current;
            const formData1 = new FormData(form1);
           console.log(formData1);
            axios.post(`https://82.180.136.212/cars/update/${id}`, formData1, {
              headers: {
                'Content-Type': 'multipart/form-data',
                accesstoken: localStorage.getItem('accessToken')
              },
            })
              .then((res) => {
              })
              .catch((error) => {
                console.error(error);
              });
              comm1.current.style.display='none';
              mess1.current.style.display='flex';
              event.preventDefault();
             
            }
          const validationSchema=yup.object().shape({
            photo:yup.mixed().required(),
            title:yup.string().required(),
            category:yup.string().required().max(1)
          })
          const validationSchema1=yup.object().shape({
            title:yup.string().required(),
            category:yup.string().required().max(1),
          })
          function Hidmess(){
            mess.current.style.display='none';
            setOption(!option);
          }
          function Hidmess1(){
            mess1.current.style.display='none';
            setOption(!option);
          }
          function ondelete(id){
            axios.delete(`https://82.180.136.212/cars/${id}`,{headers:{accesstoken:localStorage.getItem('accessToken')}}).then((res)=>{
        })
        setOption(!option);
          }

          const [car1,setCar1]=useState({title:"",id:0})
          const [category1,setCategory1]=useState('')

  return (
    <div>
      <Helmet>
        <title>
          Rent a Car in Morocco: Unforgettable Adventures Start Here!
        </title>

        <link rel="canonical" href="https://www.mdcrentalcars.com/en" />
        <meta
          name="description"
          content="Book your Morocco rental car in minutes! Explore diverse fleets, enjoy lowest prices, & discover hidden gems. Start your adventure today!"
        />

        <meta
          name="keywords"
          content="drive car, rental cars, rent a car, car hire, Morocco car rental, rent a car Morocco, cheap car rental Morocco, best car rental Morocco, luxury car rental Morocco, airport car rental Morocco, Casablanca car rental, Marrakech car rental, Agadir car rental, Fes car rental, Tangier car rental, Essaouira car rental"
        />

        <meta
          name="keywords"
          content="louer voiture, location de voiture, voiture de location, Maroc location de voiture, louer une voiture Maroc, location voiture Maroc pas cher, meilleure location voiture Maroc, location voiture luxe Maroc, location voiture aeroport Maroc, location voiture Casablanca, location voiture Marrakech, location voiture Agadir, location voiture Fes, location voiture Tanger, location voiture Essaouira"
        />

        <meta
          name="keywords"
          content="تأجير سيارات, تأجير سيارات في المغرب, استئجار سيارة في المغرب, تأجير سيارات بسعر رخيص في المغرب, أفضل تأجير سيارات في المغرب, تأجير سيارات فاخرة في المغرب, تأجير سيارات في المطار في المغرب, تأجير سيارات الدار البيضاء, تأجير سيارات مراكش, تأجير سيارات أغادير, تأجير سيارات فاس, تأجير سيارات طنجة, تأجير سيارات الصويرة"
        />

        <meta
          name="keywords"
          content="alquiler de coches, alquilar un coche, alquiler de coches en Marruecos, alquilar un coche en Marruecos, alquiler de coches baratos en Marruecos, mejor alquiler de coches en Marruecos, alquiler de coches de lujo en Marruecos, alquiler de coches en el aeropuerto de Marruecos, alquiler de coches Casablanca, alquiler de coches Marrakech, alquiler de coches Agadir, alquiler de coches Fes, alquiler de coches Tánger, alquiler de coches Essaouira"
        />

        <meta
          name="keywords"
          content="Morocco travel, Morocco vacation, Morocco tourism, Morocco holidays, Morocco road trip, Morocco adventure, Morocco sightseeing, Morocco family vacation, Morocco budget travel, Morocco luxury travel"
        />
      </Helmet>

      <div className="font-Montserrat overflow-hidden bg-[#fff] relative">
        <motion.div
          className="lg:block hidden hid"
          animate={{ scale: 0 }}
          transition={{ delay: 4.5 }}
        >
          <motion.div
            className="fixed top-0 h-screen w-[100vw] bg-white origin-top flex items-center z-[20000]"
            animate={{ opacity: 0, y: 0 }}
            transition={{ when: "afterChildren", duration: 1.2, delay: 3.3 }}
          >
            <MyIcon />
          </motion.div>
        </motion.div>

        <motion.div
          className="fixed lg:hidden flex top-0 h-screen w-[100vw] bg-myblue origin-top  items-center z-[20000]"
          animate={{ scaleY: 0, y: 0 }}
          transition={{ when: "afterChildren", duration: 1.2, delay: 3.3 }}
        >
          <MyIcon />
        </motion.div>

        <div
          className="fixed lg:hidden py-[5px] reserver px-[10px] bottom-[20px] rounded-[5px] text-[2rem] font-bold z-[3000] left-[50%] translate-x-[-50%] shadow-md bg-myblue text-white"
          onClick={() => ourcars.current.scrollIntoView({ behavior: "smooth" })}
        >
          Booking
        </div>
        <LangagueChange />
        <section className="top-container lg:pt-10 md:pt-4 pt-3 lg:px-32 md:px-16 px-8  ">
          <div className="flex h-[10%] justify-between">
            <i onClick={() => navigate("/en")} className="cursor-pointer">
              <img
                src={require("../img/Group.png")}
                alt="icon"
                className="lg:w-[4rem] lg:hidden block md:w-[2.5rem] w-[1.5rem]"
              />
              <img
                src={require("../img/Group (1).png")}
                alt="icon"
                className="lg:w-[4rem] lg:block hidden md:w-[2.5rem] w-[1.5rem]"
              />
            </i>
            <span className="relative">
              <i
                class="fa-solid fa-bars lg:text-[3rem] md:text-[2rem] text-[1rem] cursor-pointer  lg:text-white  text-myblue"
                onClick={() => {
                  document.querySelector(".fa-bars").style.display = "none";
                  document.querySelector(".fa-x").style.display = "block";
                  document.querySelector(".nav").style.display = "flex";
                }}
              ></i>
              <i
                class="fa-solid fa-x lg:text-[2.4rem] md:text-[1.7rem] text-[0.8rem] hidden cursor-pointer lg:text-white text-myblue"
                onClick={() => {
                  document.querySelector(".fa-bars").style.display = "block";
                  document.querySelector(".fa-x").style.display = "none";
                  document.querySelector(".nav").style.display = "none";
                }}
              ></i>
              <ul className="bg-bgsecond nav hidden  text-myblue lists  z-[200] flex-col rounded-md gap-2 absolute shadow-md lg:text-[1.4rem] md:text-[0.8rem] text-[0.6rem] lg:right-[2rem] right-[1.5rem] lg:w-[12vw] w-[30vw]">
                <li className=" px-[5px] py-[1.5px] font-semibold border-b">
                  <a href="/en/aboutus" className="w-full block">
                    about us
                  </a>
                </li>
                <li
                  className=" px-[5px] py-[1.5px]  font-semibold border-b cursor-pointer"
                  onClick={() =>
                    contact.current.scrollIntoView({ behavior: "smooth" })
                  }
                >
                  contact us
                </li>
                <li
                  className=" px-[5px] py-[1.5px]  font-semibold cursor-pointer"
                  onClick={() =>
                    ourcars.current.scrollIntoView({ behavior: "smooth" })
                  }
                >
                  our cars
                </li>
              </ul>
            </span>
          </div>
          <div className="lg:flex lg:flex-col grid grid-cols-9  lg:gap-[30px]  h-[100%] items-center justify-center  lg:text-white text-myblue">
            <div className=" col-span-5  flex flex-col relative">
              <p className="lg:hidden md:text-[1.5rem] text-[1.2rem] typed-text  font-[500]"></p>
              <h1 className="lg:inline-block hidden text-[4rem] coming  font-[500]">
                Find, book, and rental{" "}
              </h1>
              <h2 className="lg:inline-block hidden text-[4rem] coming text-center  font-[500]">
                car in Easy step.
              </h2>
              <span
                onClick={() =>
                  how.current.scrollIntoView({ behavior: "smooth" })
                }
                className="border-[1px] lg:hidden  cursor-pointer flex justify-center items-center self-center ml-[10%] border-myblue lg:w-[12vh] lg:h-[12vh] md:w-[8vh] md:h-[8vh] w-[4vh] h-[4vh] rounded-full"
              >
                <i className="fas  fa-arrow-down lg:text-[2.5rem]  md:text-[1.5rem] text-[0.9rem]"></i>
              </span>
            </div>
            <div className="bg-white py-10 px-4 text-[1.5rem] rounded-[5px] lg:flex hidden flex-col gap-[10px] items-center shadow-sm coming border-[0.5px] border-myblue">
              <form className="flex justify-center gap-[10px] items-center w-full">
                <select
                  className="text-myblue p-[5px] uppercase font-bold border-[0.5px] border-myblue rounded-[5px]"
                  onChange={(e) => setCategory1(e.target.value)}
                >
                  <option selected disabled>
                    Choose your category
                  </option>
                  {datacars.map((e) => (
                    <option
                      className="text-center uppercase font-bold"
                      value={e.category}
                    >
                      {e.category}
                    </option>
                  ))}
                </select>
                <select
                  className="text-myblue p-[5px] uppercase font-bold border-[0.5px] border-myblue rounded-[5px]"
                  onChange={(e) => {
                    setCar1({
                      id: e.target.value,
                      title: e.target.options[e.target.selectedIndex].text,
                    });
                    console.log({
                      id: e.target.value,
                      title: e.target.options[e.target.selectedIndex].text,
                    });
                  }}
                >
                  <option selected disabled>
                    {category1
                      ? `Choose your car from "${category1.toUpperCase()}" category`
                      : "Choose your car"}
                  </option>
                  {datacars
                    .find((e) => e.category === (category1 || "a"))
                    ?.cars.map((car, index) => (
                      <option key={index} value={car.id}>
                        {car.title}
                      </option>
                    ))}
                </select>

                <button
                  className={` ${
                    !car1.id
                      ? "bg-blue-400 pointer-events-none cursor-not-allowed"
                      : "bg-myblue cursor-pointer"
                  } text-white lg:py-2 md:py-[8px] py-[4px] lg:px-4 px-2 lg:text-[1.3rem] md:text-[1rem] text-[0.8rem] font-semibold md:rounded-md rounded-sm`}
                  onClick={() => navigate(`/car/${car1.title}/${car1.id}`)}
                >
                  Reservation
                </button>
              </form>
              <div className="flex items-end text-myblue font-semibold gap-[10px]">
                <p className="text-[1rem]">
                  If you wish to see our list of vehicles, click here{" "}
                </p>
                <p
                  className="cursor-pointer underline"
                  onClick={() =>
                    ourcars.current.scrollIntoView({ behavior: "smooth" })
                  }
                >
                  our cars
                </p>
              </div>
            </div>

            <div className="md:col-span-3 col-span-4 hamza lg:hidden block ">
              <span className="top-container-image"></span>
            </div>
          </div>
        </section>
        <section
          ref={how}
          className="flex flex-col relative items-center lg:text-[1rem] md:text-[0.6rem] text-[0.4rem] justify-center lg:gap-32 md:gap-16 gap-4 lg:py-20 md:py-10 py-6 lg:px-32 md:px-16 px-8 bg-bgsecond"
        >
          <h2 className="lg:text-[5rem] md:text-[2.5rem] z-[2] text-[1.5rem] text-myblue font-[600]">
            HOW WE{" "}
            <span className="text-myblue under relative font-bold">WORK</span>
          </h2>
          <div className="flex lg:mb-20 md:mb-10 mb-4 text-myblue z-[2] items-start justify-center lg:gap-5 md:gap-3 gap-2">
            <div className="flex flex-col items-center justify-center z-[2] lg:gap-5 md:gap-3 gap-1">
              <span className="bg-white lg:w-[10rem] md:w-[5rem] w-[3rem] lg:h-[9rem] md:h-[5rem] h-[3rem] flex items-center justify-center lg:rounded-2xl md:rounded-lg rounded-md">
                <i
                  xmlns="http://www.w3.org/2000/svg"
                  className="z-[2] fa-solid fa-circle-check lg:text-[6rem] md:text-[3rem] text-[1.5rem]"
                  width="77"
                  height="89"
                  viewBox="0 0 77 89"
                  fill="none"
                ></i>
              </span>
              <p className="font-semibold text-center w-[55%]">
                Booking and confirm
              </p>
            </div>
            <span className="border-myblue border-t-2 lg:w-[150px] md:w-[75px] w-[35px] border-dashed lg:translate-y-[70px] md:translate-y-[35px] translate-y-[17px]"></span>
            <div className="flex flex-col items-center justify-center lg:gap-5 md:gap-3 gap-1">
              <span className="bg-white lg:w-[10rem] md:w-[5rem] w-[3rem] lg:h-[9rem] md:h-[5rem] h-[3rem] flex items-center justify-center lg:rounded-2xl md:rounded-lg rounded-md">
                <i
                  xmlns="http://www.w3.org/2000/svg"
                  className="fa-solid fa-location-dot lg:text-[6rem] md:text-[3rem] text-[1.5rem]"
                  width="64"
                  height="74"
                  viewBox="0 0 64 74"
                  fill="none"
                ></i>
              </span>
              <p className="font-semibold text-center w-[55%]">Delivery</p>
            </div>
            <span className="border-myblue border-t-2 lg:w-[150px] md:w-[75px] w-[35px] border-dashed lg:translate-y-[70px] md:translate-y-[35px] translate-y-[17px]"></span>
            <div className="flex flex-col items-center justify-center lg:gap-5 md:gap-3 gap-1">
              <span className="bg-white lg:w-[10rem] md:w-[5rem] w-[3rem] lg:h-[9rem] md:h-[5rem] h-[3rem] flex items-center justify-center lg:rounded-2xl md:rounded-lg rounded-md">
                <i
                  xmlns="http://www.w3.org/2000/svg"
                  className="fa-solid fa-heart lg:text-[6rem] md:text-[3rem] text-[1.5rem]"
                  width="87"
                  height="78"
                  viewBox="0 0 87 78"
                  fill="none"
                ></i>
              </span>
              <p className="font-semibold text-center w-[55%]">
                Enjoy your trip
              </p>
            </div>
          </div>
        </section>
        <section className="grid grid-cols-7 lg:py-10 md:py-5 py-2 pl-1 lg:pr-32 md:pr-16 pr-6 bg-[#E7EDFF]">
          <div className="col-span-4 gap-4 flex items-center ">
            <div
              className="circle lg:h-[50vw] lg:w-[50vw] md:h-[50vw] md:w-[50vw] h-[50vw] w-[50vw]   flex items-center red-car relative"
              onMouseEnter={mouseOn}
              onMouseLeave={mouseLeave}
            >
              <span className="shadow">
                <span ref={shadow}></span>
              </span>
              <img
                src={require("../img/Range rover evoque.png")}
                alt="rang"
                className="translate-x-[1%] mt-[-20%] translate-y-[0%] z-[201] lg:max-w-[50vw] md:max-w-[50vw] max-w-[50vw] redcar"
                ref={redcar}
              ></img>
              <span className="absolute top-[20%] left-[4%]  flex flex-col gap-[1px] items-center">
                <span className="minicar lg:px-[10px] md:px-[5px] px-[2px]  lg:py-[5px] md:py-[3px] py-[1px]  bg-white flex justify-center items-center rounded-md ">
                  <img
                    src={require("../img/8e7bb5151b7517030db28385c68af3e8.png")}
                    alt="rang"
                    className="lg:w-[120px] mt-[-5%] md:w-[60px] w-[30px]"
                  ></img>
                </span>
                <span className="minicarcircle"></span>
              </span>
              <span className="absolute top-[15%] right-[12%] flex flex-col gap-[1px] items-center">
                <span className="minicar lg:px-[10px] md:px-[5px] px-[2px]  lg:py-[5px] md:py-[3px] py-[1px]  bg-white flex justify-center items-center rounded-md ">
                  <img
                    src={require("../img/8e7bb5151b7517030db28385c68af3e8.png")}
                    alt="rang"
                    className="lg:w-[120px] mt-[-5%] md:w-[60px] w-[30px]"
                  ></img>
                </span>
                <span className="minicarcircle"></span>
              </span>
              <span className="absolute bottom-[10%] left-[50%] flex flex-col gap-[1px] items-center">
                <span className="minicar lg:px-[10px] md:px-[5px] px-[2px]  lg:py-[5px] md:py-[3px] py-[1px]  bg-white flex justify-center items-center rounded-md ">
                  <img
                    src={require("../img/8e7bb5151b7517030db28385c68af3e8.png")}
                    alt="rang"
                    className="lg:w-[120px] mt-[-5%] md:w-[60px] w-[30px]"
                  ></img>
                </span>
                <span className="minicarcircle"></span>
              </span>
            </div>
          </div>
          <div className="col-span-3 flex flex-col text-myblue lg:gap-[30px] md:gap-[15px] gap-[7px]">
            <h2 className=" lg:text-[24px] md:text-[14px] text-[7px] font-[500] text-[#CFCFD3] ">
              WHY CHOOSE US
            </h2>
            <h3 className="lg:text-[50px] md:text-[27px] text-[10px] leading-tight font-[600]">
              We offer the best experience
            </h3>
            <div className="flex lg:gap-[10px] md:gap-[5px] gap-[2.5px] items-center">
              <span className="bg-white lg:h-[80px] lg:w-[80px] md:h-[40px] md:w-[40px] h-[20px] min-w-[20px]  flex items-center justify-center lg:rounded-xl rounded-sm">
                <i class="fas fa-briefcase lg:text-[50px] md:text-[25px] text-[10px]"></i>
              </span>
              <h3 className="lg:text-[27px] md:text-[14px] text-[7px] font-semibold">
                We offer the best experience
              </h3>
            </div>
            <div className="flex lg:gap-[10px] md:gap-[5px] gap-[2.5px]  items-center">
              <span className="bg-white lg:h-[80px] lg:w-[80px] md:h-[40px] md:w-[40px] h-[20px] w-[20px] flex items-center justify-center lg:rounded-xl rounded-sm">
                <i class="fas fa-user  lg:text-[50px] md:text-[25px] text-[10px]"></i>
              </span>
              <h3 className="lg:text-[27px] md:text-[14px] text-[7px] font-semibold">
                Experience driver
              </h3>
            </div>
            <div className="flex lg:gap-[10px] md:gap-[5px] gap-[2.5px]  items-center">
              <span className="bg-white  flex lg:h-[80px] lg:w-[80px] md:h-[40px] md:w-[40px] h-[20px] w-[20px]  items-center justify-center lg:rounded-xl rounded-sm">
                <i class="fa-solid fa-rotate lg:text-[50px] md:text-[25px] text-[10px]"></i>
              </span>
              <h3 className="lg:text-[27px] md:text-[14px] text-[7px] font-semibold">
                24-hour car delivery
              </h3>
            </div>
            <div className="flex lg:gap-[10px] md:gap-[5px] gap-[2.5px] items-center">
              <span className="bg-white  flex lg:h-[80px] lg:w-[80px] md:h-[40px] md:w-[40px] h-[20px] w-[20px] items-center justify-center lg:rounded-xl rounded-sm">
                <i class="fas fa-comments lg:text-[50px] md:text-[25px] text-[10px]"></i>
              </span>
              <h3 className="lg:text-[27px] md:text-[14px] text-[7px] font-semibold">
                27/7 technical support
              </h3>
            </div>
          </div>
        </section>
        <AuthContext.Consumer>
          {({ authState }) => (
            <section
              ref={ourcars}
              className="flex flex-col items-center lg:py-10 lg:px-32 md:py-5 md:px-16 px-8 py-3 bg-[#F2F6FF]"
            >
              <h2 className="lg:text-[5rem] md:text-[3rem] text-[1.5rem] text-myblue font-[600]">
                Book your{" "}
                <span className="text-myblue under relative after:min-w-[210%] after:ml-[-80%] after:top-[86%] font-bold">
                  car
                </span>
              </h2>
              <div className="flex flex-col w-full cars">
                {datacars.map((car, index) => {
                  return (
                    <div id={`category-${index + 1}`} className="relative">
                      <span className="lg:text-[2rem] md:text-[1rem] text-[0.5rem] font-[600]">
                        {" "}
                        <span className="bg-white capitalize text-myblue lg:px-3 lg:py-2 px-2 py-1">
                          category
                        </span>{" "}
                        <span className="bg-myblue capitalize text-white lg:px-5 lg:py-2 px-3 py-1">
                          {car.category}
                        </span>
                      </span>
                      <div className="flex overflow-x-scroll cars gap-[2vw]">
                        {car.cars.map((Car) => {
                          return (
                            <div className="relative lg:min-w-[27vw] lg:max-w-[27vw] md:max-w-[35vw] md:min-w-[35vw] max-w-[56vw] min-w-[56vw]  lg:my-10 md:my-5 my-2 lg:p-4 p-[10px] flex flex-col car lg:gap-4 md:gap-2 gap-1 bg-white lg:rounded-2xl md:rounded-lg rounded-[6px]">
                              <div className="flex justify-center">
                                {" "}
                                <img
                                  className="w-[60%] lg:h-[130px] md:h-[100px] h-[80px]   "
                                  alt={"image" + Car.title}
                                  src={Car.front?Car.image:` https://82.180.136.212/cars/images/${Car.id}`}
                                />
                              </div>
                              <div className="flex justify-between">
                                <span>
                                  <h3 className="text-myblue lg:text-[1.6rem] md:text-[1.4rem] text-[1.1rem] font-[600]">
                                    {Car.title}
                                  </h3>
                                  <p className="font-semibold lg:tracking-[0.04rem] md:tracking-[0.02rem] tracking-[0.01rem] lg:text-[0.8rem] md:text-[0.6rem] text-[0.5rem] text-myblue">
                                    Or similar
                                  </p>
                                </span>
                                <span className="flex items-center lg:gap-2 gap-[2px]">
                                  <span className="flex flex-col ">
                                    <h4 className="text-[#4B91EC] self-end lg:text-[1.1rem] md:text-[0.8rem] text-[0.5rem] font-[500]">
                                      Good
                                    </h4>
                                    <a
                                      rel="nofollow noopener noreferrer"
                                      href="https://www.google.com/maps/place/My+drive+car/@31.6106031,-8.0527586,15z/data=!4m17!1m8!3m7!1s0xdafefb6d566b4c1:0x274d9b23e13e297f!2sMy+drive+car!8m2!3d31.5972256!4d-8.0517715!10e1!16s%2Fg%2F11pf1pd_rh!3m7!1s0xdafefb6d566b4c1:0x274d9b23e13e297f!8m2!3d31.5972256!4d-8.0517715!9m1!1b1!16s%2Fg%2F11pf1pd_rh?entry=ttu"
                                      target="_blank"
                                      className="text-[#d0cfcf] lg:text-[0.7rem] whitespace-nowrap md:text-[0.5rem] text-[0.3rem] font-[500]"
                                    >
                                      +20 reviews
                                    </a>
                                  </span>
                                  <span className="lg:text-[1rem] md:text-[0.8rem] text-[0.6rem] text-center font-bold  text-white rounded-tl-md rounded-br-md rounded-tr-md  lg:p-2  p-1 bg-[#4B91EC]">
                                    5.5
                                  </span>
                                </span>
                              </div>
                              <div className="flex justify-between md:my-[0] my-[10px] text-[#121ee4b3] font-semibold lg:text-[0.9rem] md:text-[0.7rem] text-[0.5rem]">
                                <span>
                                  <span className="flex gap-2">
                                    <i class="fa-solid fa-user lg:text-[1rem] md:text-[0.7rem] text-[0.6rem]"></i>
                                    <p>{Car.title === "Dacia lodgy" ? 7 : 5}</p>
                                  </span>
                                  <span className="flex gap-1 mt-2">
                                    <i class="fa-solid fa-gas-pump lg:text-[1rem] md:text-[0.7rem] text-[0.5rem]"></i>
                                    <p>Gasoline or Diesel</p>
                                  </span>
                                </span>
                                <span>
                                  <span className="flex justify-start gap-1">
                                    <i
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="fas fa-car-side lg:text-[1rem] md:text-[0.7rem] text-[0.5rem]"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    ></i>
                                    <p>Automatic or Manual</p>
                                  </span>
                                  <span className="flex justify-start gap-1 mt-2 ">
                                    <i className="fa-solid fa-wind"></i>
                                    <p>Air conditioning</p>
                                  </span>
                                </span>
                              </div>
                              <span className="lg:text-[1.2rem] md:text-[0.6rem] text-[0.5rem] font-medium px-3 py-[2px] max-w-fit border border-[#121ee4b3] text-[#121ee4b3]">
                                All-risk insurance
                              </span>
                              <div className="lg:py-4 md:py-2 py-1 flex items-center justify-between">
                                <div className="prix lg:text-[2.6rem] md:text-[1.3rem] text-[1.1rem] font-semibold text-myblue">
                                  {Car.prix}
                                  <span className="lg:text-[1rem] md:text-[0.6rem] text-[0.4rem]">
                                    Per day
                                  </span>
                                </div>
                                <span className="flex justify-end items-center md:gap-[8px] gap-[4px]">
                                  <a
                                    className="text-white bg-myblue lg:py-2 md:py-[8px] py-[4px] lg:px-4 px-2 lg:text-[1.3rem] md:text-[1rem] text-[0.8rem] font-semibold  md:rounded-md  rounded-sm"
                                    href={`/en/car/${Car.title}/${Car.id}`}
                                  >
                                    Booking
                                  </a>
                                  <a
                                    href="https://wa.me/+212666703720"
                                    rel="nofollow noopener noreferrer"
                                    target="__blank"
                                    className="fab fa-whatsapp text-white lg:text-[2rem] md:text-[1.4rem]  text-[1.2rem] md:shadow-md shadow-sm md:p-[6px] p-[4px] md:rounded-md rounded-sm bg-green-500"
                                  >
                                    <i />
                                  </a>
                                </span>
                              </div>
                              {authState.status ? (
                                <div className="absolute bg-green-300 p-[4px] z-100 top-[95%] left-[30%] z-100 flex md:text-[1rem] text-[0.5rem] items-center  gap-4">
                                  <span
                                    className="fas fa-trash-alt md:text-[1rem] text-[0.4rem]  cursor-pointer text-gray-600"
                                    onClick={() => ondelete(Car.id)}
                                  ></span>
                                  <span
                                    className=" cursor-pointer rounded-md px-4  whitespace-nowrap  bg-myblue text-white"
                                    onClick={() => {
                                      setId(Car.id);
                                      comm1.current.style.display = "flex";
                                    }}
                                  >
                                    update car
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex justify-center lg:mb-10 md:mb-5 mb-3 ">
                        <i
                          onClick={() => onmoveLeft(index + 1)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-myblue lg:text-[3rem] md:text-[2.2rem] text-[1.5rem] cursor-pointer fa-solid fa-caret-left"
                          width="43"
                          height="42"
                          viewBox="0 0 43 42"
                          fill="none"
                        ></i>
                        <i
                          onClick={() => onmoveRight(index + 1)}
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-myblue lg:text-[3rem] md:text-[2.2rem] text-[1.5rem] cursor-pointer fa-solid fa-caret-right"
                          width="42"
                          height="42"
                          viewBox="0 0 42 42"
                          fill="none"
                        ></i>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                {authState.status ? (
                  <button
                    className="px-4 py-2 text-bgsecond bg-myblue mb-5"
                    onClick={() => {
                      comm.current.style.display = "flex";
                    }}
                  >
                    Add car
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </section>
          )}
        </AuthContext.Consumer>
        <section className="flex flex-col items-center lg:py-10 md:py-5 py-3  lg:px-32 md:px-16 px-8 bg-[#F2F6FF]">
          <h2 className="lg:text-[5rem] md:text-[2.5rem] text-[1.25rem] text-myblue font-[600] ">
            What they say{" "}
            <span className="text-myblue under relative after:top-[90%]  font-bold">
              {" "}
              about Us{" "}
            </span>{" "}
          </h2>
          <div className="views flex justify-start w-full overflow-x-scroll lg:gap-4  gap-[12px] lg:my-14 md:my-10 my-7">
            <div className="flex items-center  comment flex-col md:min-w-[60vw] min-w-[80vw] gap-2 text-myblue lg:px-8 md:px-4 px-2 lg:py-4 md:py-2 py-1 bg-[#E7EDFF]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="lg:w-[10rem] md:w-[5rem] w-[3rem]"
                width="160"
                height="32"
                viewBox="0 0 160 32"
                fill="none"
              >
                <path
                  d="M16 0L19.5922 11.0557H31.2169L21.8123 17.8885L25.4046 28.9443L16 22.1115L6.59544 28.9443L10.1877 17.8885L0.783095 11.0557H12.4078L16 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M48 0L51.5922 11.0557H63.2169L53.8123 17.8885L57.4046 28.9443L48 22.1115L38.5954 28.9443L42.1877 17.8885L32.7831 11.0557H44.4078L48 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M80 0L83.5922 11.0557H95.2169L85.8123 17.8885L89.4046 28.9443L80 22.1115L70.5954 28.9443L74.1877 17.8885L64.7831 11.0557H76.4078L80 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M112 0L115.592 11.0557H127.217L117.812 17.8885L121.405 28.9443L112 22.1115L102.595 28.9443L106.188 17.8885L96.7831 11.0557H108.408L112 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M144 0L147.592 11.0557H159.217L149.812 17.8885L153.405 28.9443L144 22.1115L134.595 28.9443L138.188 17.8885L128.783 11.0557H140.408L144 0Z"
                  fill="#F7B329"
                />
              </svg>
              <p className="lg:text-[1.4rem] md:text-[1rem] text-[0.6rem] text-center font-[400]">
                One of the best car rental agencies. The agents who deliver the
                cars are friendly and especially the boss Mr Younes, the cars
                correspond to the category ordered. No excess charged. I
                recommend
              </p>
              <h3 className="lg:text-[1.3rem] md:text-[1rem] text-[0.6rem] font-[400]">
                -Ghizlane Ouachani
              </h3>
            </div>
            <div className="flex items-center comment flex-col  md:min-w-[60vw] min-w-[80vw] gap-2 text-myblue lg:px-8 md:px-4 px-2 lg:py-4 md:py-2 py-1 bg-[#E7EDFF]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="lg:w-[10rem] md:w-[5rem] w-[3rem]"
                width="160"
                height="32"
                viewBox="0 0 160 32"
                fill="none"
              >
                <path
                  d="M16 0L19.5922 11.0557H31.2169L21.8123 17.8885L25.4046 28.9443L16 22.1115L6.59544 28.9443L10.1877 17.8885L0.783095 11.0557H12.4078L16 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M48 0L51.5922 11.0557H63.2169L53.8123 17.8885L57.4046 28.9443L48 22.1115L38.5954 28.9443L42.1877 17.8885L32.7831 11.0557H44.4078L48 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M80 0L83.5922 11.0557H95.2169L85.8123 17.8885L89.4046 28.9443L80 22.1115L70.5954 28.9443L74.1877 17.8885L64.7831 11.0557H76.4078L80 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M112 0L115.592 11.0557H127.217L117.812 17.8885L121.405 28.9443L112 22.1115L102.595 28.9443L106.188 17.8885L96.7831 11.0557H108.408L112 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M144 0L147.592 11.0557H159.217L149.812 17.8885L153.405 28.9443L144 22.1115L134.595 28.9443L138.188 17.8885L128.783 11.0557H140.408L144 0Z"
                  fill="#F7B329"
                />
              </svg>
              <p className="lg:text-[1.4rem] md:text-[0.7rem] text-[0.6rem] text-center font-[400]">
                Very good service. Nothing to say. Punctual. The car still in
                perfect condition. I rent from them as soon as I arrive in
                Marrakech
              </p>
              <h3 className="lg:text-[1.3rem] md:text-[0.7rem] text-[0.6rem] font-[400]">
                -Jamal Bensadik
              </h3>
            </div>
            <div className="flex items-center comment flex-col md:min-w-[60vw] min-w-[80vw] gap-2 text-myblue lg:px-8 md:px-4 px-2 lg:py-4 md:py-2 py-1 bg-[#E7EDFF]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="lg:w-[10rem] md:w-[5rem] w-[3rem]"
                width="160"
                height="32"
                viewBox="0 0 160 32"
                fill="none"
              >
                <path
                  d="M16 0L19.5922 11.0557H31.2169L21.8123 17.8885L25.4046 28.9443L16 22.1115L6.59544 28.9443L10.1877 17.8885L0.783095 11.0557H12.4078L16 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M48 0L51.5922 11.0557H63.2169L53.8123 17.8885L57.4046 28.9443L48 22.1115L38.5954 28.9443L42.1877 17.8885L32.7831 11.0557H44.4078L48 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M80 0L83.5922 11.0557H95.2169L85.8123 17.8885L89.4046 28.9443L80 22.1115L70.5954 28.9443L74.1877 17.8885L64.7831 11.0557H76.4078L80 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M112 0L115.592 11.0557H127.217L117.812 17.8885L121.405 28.9443L112 22.1115L102.595 28.9443L106.188 17.8885L96.7831 11.0557H108.408L112 0Z"
                  fill="#F7B329"
                />
                <path
                  d="M144 0L147.592 11.0557H159.217L149.812 17.8885L153.405 28.9443L144 22.1115L134.595 28.9443L138.188 17.8885L128.783 11.0557H140.408L144 0Z"
                  fill="#F7B329"
                />
              </svg>
              <p className="lg:text-[1.4rem] md:text-[0.7rem] text-[0.6rem] text-center font-[400]">
                The warm and professional welcome, the reasonable price, but the
                vehicle that I had to maintain and it is not what I expected.
                but I will return.
              </p>
              <h3 className="lg:text-[1.3rem] md:text-[0.7rem] text-[0.6rem] font-[400]">
                -Omar El maknassi
              </h3>
            </div>
          </div>
          <div className="flex">
            <i
              xmlns="http://www.w3.org/2000/svg"
              onClick={toMoveLeft}
              className="text-myblue lg:text-[3rem] md:text-[2.2rem] text-[1.5rem] cursor-pointer fa-solid fa-caret-left"
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
            ></i>
            <i
              xmlns="http://www.w3.org/2000/svg"
              onClick={toMoveRight}
              className="text-myblue lg:text-[3rem] md:text-[2.2rem] text-[1.5rem] cursor-pointer fa-solid fa-caret-right"
              width="43"
              height="42"
              viewBox="0 0 43 42"
              fill="none"
            ></i>
          </div>
          <a
            href="https://www.google.com/maps/place/My+drive+car/@31.6106031,-8.0527586,15z/data=!4m17!1m8!3m7!1s0xdafefb6d566b4c1:0x274d9b23e13e297f!2sMy+drive+car!8m2!3d31.5972256!4d-8.0517715!10e1!16s%2Fg%2F11pf1pd_rh!3m7!1s0xdafefb6d566b4c1:0x274d9b23e13e297f!8m2!3d31.5972256!4d-8.0517715!9m1!1b1!16s%2Fg%2F11pf1pd_rh?entry=ttu"
            target="__blank"
            className="my-8 lg:text-[1.5rem] md:text-[1rem] text-[0.7rem] hover:underline font-[600] text-myblue"
          >
            More reviews...
          </a>
        </section>
        <section className="lg:py-10 md:py-5 py-3  bg-[#F2F6FF] md:gap-0 gap-4 md:flex hidden flex-col items-center">
          <h2 className="lg:text-[5rem] md:text-[2.5rem] text-[1.25rem] text-myblue font-[600] ">
            <span className="under relative after:top-[90%]  after:ml-[40%] after:min-w-fit">
              Blogs
            </span>
          </h2>
          <div className="w-full  lg:my-10 md:my-6 my-2 ">
            <Swiper
              // Enable autoplay with a delay of 5000 milliseconds (5 seconds)
              onSwiper={(swiper) => setSwiper(swiper)}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              autoplay={{ delay: 1000 }}
              coverflowEffect={{
                rotate: 30, // Adjust rotation angle
                stretch: 10, // Increase stretch for a more pronounced effect
                depth: 300, // Increase depth for more distance between slides
                modifier: 1,
                slideShadows: false,
              }}
              slidesPerView={2} // Display three slides at once
              spaceBetween={30} // Adjust the space between slides
              className="overflow-x-scroll    w-full "
              modules={[Navigation, Pagination, Scrollbar, A11y]}
            >
              {" "}
              <SwiperSlide>
                <a
                  href="/en/blog/10 Essential Travel Tips for a Smooth Road Trip Experience/1"
                  className="cart cart1 flex flex-col self-center justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow  md:text-[1.4rem] text-[0.8rem] font-semibold">
                    10 Essential Travel Tips for a Smooth Road Trip Experience
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Real Stories, Real Experiences: Customer Reviews of MY DRIVE CAR/2"
                  className="cart cart2 flex flex-col  justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl"
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Real Stories, Real Experiences: Customer Reviews of MY DRIVE
                    CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Navigating Moroccan Laws: A Guide for Visitors and Expats/3"
                  className="cart  cart3 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Navigating Moroccan Laws: A Guide for Visitors and Expats
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Unlock Morocco's Magic: Unveiling Hidden Gems with Car Rentals/4"
                  className="cart  cart4 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Unlock Morocco's Magic: Unveiling Hidden Gems with Car
                    Rentals
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Navigating Marrakech's City Labyrinth: Embark on a Red City Adventure with My Drive Car/5"
                  className="cart  cart5 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Navigating Marrakech's City Labyrinth: Embark on a Red City
                    Adventure with My Drive Car
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Agadir Coastal Explorations: Your Guide to Hidden Beaches, Charming Villages, and More/6"
                  className="cart  cart6 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Agadir Coastal Explorations: Your Guide to Hidden Beaches,
                    Charming Villages, and More
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Rent a Car in Morocco: Your Essential Guide to Hassle-Free Driving Adventures/7"
                  className="cart  cart7 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Rent a Car in Morocco: Your Essential Guide to Hassle-Free
                    Driving Adventures
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Tangier Tales Unfold: Experience Cultural Odysseys with MY DRIVE CAR/8"
                  className="cart  cart8 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Tangier Tales Unfold: Experience Cultural Odysseys with MY
                    DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Cruise Casablanca's Streets: Explore Morocco's Metropolis with MY DRIVE CAR/9"
                  className="cart  cart9 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Cruise Casablanca's Streets: Explore Morocco's Metropolis
                    with MY DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Unforgettable Adventures Await: Luxury Car Rentals with MY DRIVE CAR in Morocco/10"
                  className="cart  cart10 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Unforgettable Adventures Await: Luxury Car Rentals with MY
                    DRIVE CAR in Morocco
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Seamless Arrivals: Simplify Your Moroccan Adventure with MY DRIVE CAR/11"
                  className="cart  cart11 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl"
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Seamless Arrivals: Simplify Your Moroccan Adventure with MY
                    DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Business Travel Made Easy: Navigate Morocco with MY DRIVE CAR's Monthly Solutions/12"
                  className="cart  cart12 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Business Travel Made Easy: Navigate Morocco with MY DRIVE
                    CAR's Monthly Solutions
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Extended Stays, Unending Experiences: Explore Morocco at Your Own Pace with MY DRIVE CAR/13"
                  className="cart  cart13 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Extended Stays, Unending Experiences: Explore Morocco at
                    Your Own Pace with MY DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Group Getaways Made Easy: Experience Morocco Together with MY DRIVE CAR's Van Rentals/14"
                  className="cart  cart14 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Extended Stays, Unending Experiences: Explore Morocco at
                    Your Own Pace with MY DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Off-Road Excitement Unleashed: Discover Morocco's Rugged Terrain with MY DRIVE CAR's 4x4s/15"
                  className="cart  cart15 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Off-Road Excitement Unleashed: Discover Morocco's Rugged
                    Terrain with MY DRIVE CAR's 4x4s
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Scenic Drives and Open-Air Thrills: Embrace Morocco's Beauty with MY DRIVE CAR's Convertibles/16"
                  className="cart  cart16 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Scenic Drives and Open-Air Thrills: Embrace Morocco's Beauty
                    with MY DRIVE CAR's Convertibles
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Morocco Convertible Road Trip: Unforgettable Drives & Expert Tips for MY DRIVE CAR Renters/17"
                  className="cart  cart17 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Morocco Convertible Road Trip: Unforgettable Drives & Expert
                    Tips for MY DRIVE CAR Renters
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Luxury Car Rentals in Morocco: Elevate Your Adventure with MY DRIVE CAR/18"
                  className="cart  cart18 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Luxury Car Rentals in Morocco: Elevate Your Adventure with
                    MY DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Hit the Road in Morocco: 5 Affordable Week-Long Adventures on a Budget/19"
                  className="cart  cart19 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl"
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Hit the Road in Morocco: 5 Affordable Week-Long Adventures
                    on a Budget
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Driving in Morocco: Essential Tips for City Streets and Desert Highways/20"
                  className="cart  cart20 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Driving in Morocco: Essential Tips for City Streets and
                    Desert Highways
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Morocco Car Rental Made Easy: Essential FAQs Answered for a Safe & Fun Adventure/21"
                  className="cart  cart21 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl"
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Morocco Car Rental Made Easy: Essential FAQs Answered for a
                    Safe & Fun Adventure
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Buckle Up for Adventure: Family Road Trip Gems in Morocco/22"
                  className="cart  cart22 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Buckle Up for Adventure: Family Road Trip Gems in Morocco
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Drive to Paradise: Unveiling Morocco's Romantic Road Trip Secrets/23"
                  className="cart  cart23 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Drive to Paradise: Unveiling Morocco's Romantic Road Trip
                    Secrets
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Morocco Desert 4x4 Rental Guide: Conquer Your Off-Road Adventure Safely & Responsibly/24"
                  className="cart  cart24 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Morocco Desert 4x4 Rental Guide: Conquer Your Off-Road
                    Adventure Safely & Responsibly
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/ Conquering Morocco's Trails: The Essential Guide to Choosing Your Camping Rental Car/25"
                  className="cart  cart25 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    {" "}
                    Conquering Morocco's Trails: The Essential Guide to Choosing
                    Your Camping Rental Car
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Explore Morocco's Hidden Gems: A Self-Drive Adventure Guide/26"
                  className="cart  cart26 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    {" "}
                    Explore Morocco's Hidden Gems: A Self-Drive Adventure Guide
                  </h3>
                </a>
              </SwiperSlide>
              <div className="slider-controler  md:flex hidden p-[30px]">
                <div
                  className="swiper-button-prev slider-arrow text-myblue   p-[30px] font-bold text-center"
                  onClick={() => swiper.slidePrev()}
                >
                  <ion-icon className="arrow-back-outline text-[0.5rem] "></ion-icon>
                </div>
                <div
                  className="swiper-button-next slider-arrow text-myblue   p-[30px]  font-bold text-center"
                  onClick={() => swiper.slideNext()}
                >
                  <ion-icon className="arrow-forward-outline"></ion-icon>
                </div>
              </div>
            </Swiper>
          </div>
        </section>
        <section className="lg:py-10 md:hidden  md:py-5 py-3  bg-[#F2F6FF] md:gap-0 gap-4 flex flex-col items-center">
          <h2 className="lg:text-[5rem] md:text-[2.5rem] text-[1.25rem] text-myblue font-[600] ">
            <span className="under relative after:top-[90%]  after:ml-[40%] after:min-w-fit">
              Blogs
            </span>
          </h2>
          <div className="w-full  lg:my-10 md:my-6 my-2 ">
            <Swiper
              // Enable autoplay with a delay of 5000 milliseconds (5 seconds)
              onSwiper={(swiper) => setSwiper(swiper)}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              autoplay={{ delay: 1000 }}
              coverflowEffect={{
                rotate: 30, // Adjust rotation angle
                stretch: 10, // Increase stretch for a more pronounced effect
                depth: 300, // Increase depth for more distance between slides
                modifier: 1,
                slideShadows: false,
              }}
              slidesPerView={2} // Display three slides at once
              spaceBetween={200} // Adjust the space between slides
              className="overflow-x-scroll    w-full "
              modules={[Navigation, Pagination, Scrollbar, A11y]}
            >
              {" "}
              <SwiperSlide>
                <a
                  href="/en/blog/10 Essential Travel Tips for a Smooth Road Trip Experience/1"
                  className="cart cart1 flex flex-col self-center justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow  md:text-[1.4rem] text-[0.8rem] font-semibold">
                    10 Essential Travel Tips for a Smooth Road Trip Experience
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Real Stories, Real Experiences: Customer Reviews of MY DRIVE CAR/2"
                  className="cart cart2 flex flex-col  justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl"
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Real Stories, Real Experiences: Customer Reviews of MY DRIVE
                    CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Navigating Moroccan Laws: A Guide for Visitors and Expats/3"
                  className="cart  cart3 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Navigating Moroccan Laws: A Guide for Visitors and Expats
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Unlock Morocco's Magic: Unveiling Hidden Gems with Car Rentals/4"
                  className="cart  cart4 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Unlock Morocco's Magic: Unveiling Hidden Gems with Car
                    Rentals
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Navigating Marrakech's City Labyrinth: Embark on a Red City Adventure with My Drive Car/5"
                  className="cart  cart5 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Navigating Marrakech's City Labyrinth: Embark on a Red City
                    Adventure with My Drive Car
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Agadir Coastal Explorations: Your Guide to Hidden Beaches, Charming Villages, and More/6"
                  className="cart  cart6 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Agadir Coastal Explorations: Your Guide to Hidden Beaches,
                    Charming Villages, and More
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Rent a Car in Morocco: Your Essential Guide to Hassle-Free Driving Adventures/7"
                  className="cart  cart7 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Rent a Car in Morocco: Your Essential Guide to Hassle-Free
                    Driving Adventures
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Tangier Tales Unfold: Experience Cultural Odysseys with MY DRIVE CAR/8"
                  className="cart  cart8 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Tangier Tales Unfold: Experience Cultural Odysseys with MY
                    DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Cruise Casablanca's Streets: Explore Morocco's Metropolis with MY DRIVE CAR/9"
                  className="cart  cart9 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Cruise Casablanca's Streets: Explore Morocco's Metropolis
                    with MY DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Unforgettable Adventures Await: Luxury Car Rentals with MY DRIVE CAR in Morocco/10"
                  className="cart  cart10 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Unforgettable Adventures Await: Luxury Car Rentals with MY
                    DRIVE CAR in Morocco
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Seamless Arrivals: Simplify Your Moroccan Adventure with MY DRIVE CAR/11"
                  className="cart  cart11 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl"
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Seamless Arrivals: Simplify Your Moroccan Adventure with MY
                    DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Business Travel Made Easy: Navigate Morocco with MY DRIVE CAR's Monthly Solutions/12"
                  className="cart  cart12 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Business Travel Made Easy: Navigate Morocco with MY DRIVE
                    CAR's Monthly Solutions
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Extended Stays, Unending Experiences: Explore Morocco at Your Own Pace with MY DRIVE CAR/13"
                  className="cart  cart13 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Extended Stays, Unending Experiences: Explore Morocco at
                    Your Own Pace with MY DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Group Getaways Made Easy: Experience Morocco Together with MY DRIVE CAR's Van Rentals/14"
                  className="cart  cart14 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Extended Stays, Unending Experiences: Explore Morocco at
                    Your Own Pace with MY DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Off-Road Excitement Unleashed: Discover Morocco's Rugged Terrain with MY DRIVE CAR's 4x4s/15"
                  className="cart  cart15 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Off-Road Excitement Unleashed: Discover Morocco's Rugged
                    Terrain with MY DRIVE CAR's 4x4s
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Scenic Drives and Open-Air Thrills: Embrace Morocco's Beauty with MY DRIVE CAR's Convertibles/16"
                  className="cart  cart16 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Scenic Drives and Open-Air Thrills: Embrace Morocco's Beauty
                    with MY DRIVE CAR's Convertibles
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Morocco Convertible Road Trip: Unforgettable Drives & Expert Tips for MY DRIVE CAR Renters/17"
                  className="cart  cart17 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Morocco Convertible Road Trip: Unforgettable Drives & Expert
                    Tips for MY DRIVE CAR Renters
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Luxury Car Rentals in Morocco: Elevate Your Adventure with MY DRIVE CAR/18"
                  className="cart  cart18 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Luxury Car Rentals in Morocco: Elevate Your Adventure with
                    MY DRIVE CAR
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Hit the Road in Morocco: 5 Affordable Week-Long Adventures on a Budget/19"
                  className="cart  cart19 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl"
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Hit the Road in Morocco: 5 Affordable Week-Long Adventures
                    on a Budget
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Driving in Morocco: Essential Tips for City Streets and Desert Highways/20"
                  className="cart  cart20 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Driving in Morocco: Essential Tips for City Streets and
                    Desert Highways
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Morocco Car Rental Made Easy: Essential FAQs Answered for a Safe & Fun Adventure/21"
                  className="cart  cart21 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl"
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Morocco Car Rental Made Easy: Essential FAQs Answered for a
                    Safe & Fun Adventure
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Buckle Up for Adventure: Family Road Trip Gems in Morocco/22"
                  className="cart  cart22 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Buckle Up for Adventure: Family Road Trip Gems in Morocco
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Drive to Paradise: Unveiling Morocco's Romantic Road Trip Secrets/23"
                  className="cart  cart23 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Drive to Paradise: Unveiling Morocco's Romantic Road Trip
                    Secrets
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Morocco Desert 4x4 Rental Guide: Conquer Your Off-Road Adventure Safely & Responsibly/24"
                  className="cart  cart24 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    Morocco Desert 4x4 Rental Guide: Conquer Your Off-Road
                    Adventure Safely & Responsibly
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/ Conquering Morocco's Trails: The Essential Guide to Choosing Your Camping Rental Car/25"
                  className="cart  cart25 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    {" "}
                    Conquering Morocco's Trails: The Essential Guide to Choosing
                    Your Camping Rental Car
                  </h3>
                </a>
              </SwiperSlide>
              <SwiperSlide>
                <a
                  href="/en/blog/Explore Morocco's Hidden Gems: A Self-Drive Adventure Guide/26"
                  className="cart  cart26 flex flex-col justify-end lg:px-8 md:px-4 px-2 lg:py-2 py-1 hover:shadow-2xl "
                >
                  <h3 className=" text-white lg:text-[2rem] text-shadow md:text-[1.4rem] text-[0.8rem] font-semibold">
                    {" "}
                    Explore Morocco's Hidden Gems: A Self-Drive Adventure Guide
                  </h3>
                </a>
              </SwiperSlide>
              <div className="slider-controler  md:flex hidden p-[30px]">
                <div
                  className="swiper-button-prev slider-arrow text-myblue   p-[30px] font-bold text-center"
                  onClick={() => swiper.slidePrev()}
                >
                  <ion-icon className="arrow-back-outline text-[0.5rem] "></ion-icon>
                </div>
                <div
                  className="swiper-button-next slider-arrow text-myblue   p-[30px]  font-bold text-center"
                  onClick={() => swiper.slideNext()}
                >
                  <ion-icon className="arrow-forward-outline"></ion-icon>
                </div>
              </div>
            </Swiper>
          </div>
        </section>
        <section
          ref={contact}
          className="bg-[#E7EDFF] lg:px-32 md:px-16 px-8 lg:py-10 md:py-5 py-3"
        >
          <div className="flex md:flex-row flex-col gap-[30%] text-myblue">
            <div className="flex flex-col lg:gap-4 gap-2 md:w-[30%] w-[100%]">
              <h3 className="lg:text-[1.6rem] text-[1.2rem]  font-[500]">
                In writing your email
              </h3>
              <p className="lg:text-[1.4rem] text-[0.8rem] font-[400]">
                we will contact you whenever we have a lot of requests so that
                you will always be ahead.
              </p>
              <form
                className="flex items-center"
                ref={email}
                onSubmit={sendEmail}
              >
                <input
                  formMethod="post"
                  className="lg:w-[20rem] lg:text-[1rem]  text-[0.8rem] md:w-[12rem] w-[8rem] lg:h-[4rem] h-[2rem] rounded-none  lg:p-4 md:p-2 p-1 placeholder:text-[#AAC6FF]"
                  name="email"
                  placeholder="Email adress"
                />
                <button>
                  <i class="fa-solid flex justify-center items-center fa-arrow-right lg:h-[4rem] md:h-[2rem] h-[2rem] lg:p-4 md:p-2 p-1 lg:w-[4.3rem] w-[2.2rem]  text-white bg-myblue lg:text-[1.5rem]  text-[0.8rem]"></i>
                </button>
              </form>
              <div className="flex gap-[18%] lg:text-[1.5rem] text-[0.8rem] ">
                <a
                  rel="nofollow noopener noreferrer"
                  href="https://www.facebook.com/MDC.rentalcars"
                  target="_blank"
                  className="fab fa-facebook"
                >
                  <i />
                </a>
                <a
                  rel="nofollow noopener noreferrer"
                  href="http://m.me/MDC.rentalcars"
                  target="_blank"
                  className="fab fa-facebook-messenger"
                >
                  <i />
                </a>
                <a
                  rel="nofollow noopener noreferrer"
                  href=" https://www.instagram.com/my_drive_car/"
                  class="fa-brands fa-instagram"
                  target="_blank"
                >
                                    <i />

                </a>
                <a
                  rel="nofollow noopener noreferrer"
                  target="_blank"
                  href="https://wa.me/+212666703720"
                  class="fa-brands fa-whatsapp"
                >
                  <i />
                </a>
              </div>
            </div>
            <div className="flex flex-col lg:gap-8 md:gap-4 gap-2 lg:text-[1.6rem] text-[1.2rem] ">
              <a
                href="mailto:Contact@mdcrentalcars.com"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Contact@mdcrentalcars.com
              </a>
              <a
                href="https://goo.gl/maps/MNhaT5xYumiF6fzR8"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                Marrakech-Morocco
              </a>
              <a
                href="tel:+212666703720"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                +212666703720
              </a>
            </div>
          </div>
        </section>
        <Footer px={32} />

        <Formik
          initialValues={{ photo: null, title: "", text: "" }}
          onSubmit={addCar}
          validationSchema={validationSchema}
        >
          <Form
            ref={comm}
            className="fixed formscroll border-4 overflow-y-scroll z-10 max-h-[100vh] hidden border-black top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[0.7rem] md:text-[1rem] md:w-[40vw] w-[80%] flex-col bg-myblue p-4 md:p-8 items-center justify-center lg:gap-8 md:gap-4 gap-2"
          >
            <span className="w-full flex justify-between">
              <span></span>
              <span
                className="bg-bgsecond px-4 cursor-pointer"
                onClick={() => {
                  comm.current.style.display = "none";
                }}
              >
                x
              </span>
            </span>
            <label className="lg:text-[2rem] md:text-[1.5rem] text-[1rem] text-bgsecond">
              image:
            </label>
            <Field type="file" multiple className="w-[80%]" name="photo" />
            <ErrorMessage name="photo" component="span" />
            <label className="lg:text-[2rem] md:text-[1.5rem] text-[1rem] text-bgsecond">
              title:
            </label>
            <Field type="text" className="w-[80%]" name="title" />
            <ErrorMessage name="title" component="span" />
            <label className="lg:text-[2rem] md:text-[1.5rem] text-[1rem] text-bgsecond">
              category:
            </label>
            <Field className="w-[80%]" type="text" name="category" />
            <ErrorMessage name="category" component="span" />
            <label className="lg:text-[2rem] md:text-[1.5rem] text-[1rem] text-bgsecond">
              prix:
            </label>
            <Field className="w-[80%]" type="number" name="prix" />
            <ErrorMessage name="prix" component="span" />
            <button type="submit" className="px-4 py-2 bg-bgsecond">
              add car
            </button>
          </Form>
        </Formik>
        <Formik
          initialValues={{ title: "", text: "" }}
          onSubmit={updateCar}
          validationSchema={validationSchema1}
          style={{ maxHeight: "100vh", overflow: "scroll" }}
        >
          <Form
            ref={comm1}
            className="fixed formscroll border-4 hidden border-black top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-[0.7rem] md:text-[1rem] md:w-[40vw] w-[80%] flex-col bg-myblue p-4 md:p-8 items-center justify-center lg:gap-8 md:gap-4 gap-2"
          >
            <span className="w-full flex justify-between">
              <span></span>
              <span
                className="bg-bgsecond px-3 cursor-pointer "
                onClick={() => {
                  comm1.current.style.display = "none";
                }}
              >
                x
              </span>
            </span>
            <label className="lg:text-[2rem] md:text-[1.5rem] text-[1rem] text-bgsecond">
              title
            </label>
            <Field type="text" className="w-[80%]" name="title" />
            <ErrorMessage name="title" component="span" />
            <label className="lg:text-[2rem] md:text-[1.5rem] text-[1rem] text-bgsecond">
              category
            </label>
            <Field className="w-[80%]" type="text" name="category" />
            <ErrorMessage name="category" component="span" />
            <label className="lg:text-[2rem] md:text-[1.5rem] text-[1rem] text-bgsecond">
              prix:
            </label>
            <Field className="w-[80%]" type="number" name="prix" />
            <ErrorMessage name="prix" component="span" />
            <button type="submit" className="px-4 py-2 bg-bgsecond">
              update Car
            </button>
          </Form>
        </Formik>
        <div
          ref={mess}
          onClick={Hidmess}
          className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-bgsecond p-4 h-[30vh] hidden items-center border-2 border-myblue"
        >
          you add your blog succefully
        </div>
        <div
          ref={mess1}
          onClick={Hidmess1}
          className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-bgsecond p-4 h-[30vh] hidden items-center border-2 border-myblue"
        >
          you update your blog succefully
        </div>
      </div>
    </div>
  );
}

export default DriveCaar