import axios from 'axios'
import React, { useState ,useEffect, useContext, useRef} from 'react'
import { AuthContext } from '../config/authContext';

function Admin() {
  const formU=useRef(null);
  const mess=useRef(null);
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    const {setAuthState}=useContext(AuthContext);
    const [emailU,setEmailU]=useState('');
    const [option,setOption]=useState(false);
    const [passwordU,setPasswordU]=useState('');
    useEffect(()=>{
      axios.get('https://82.180.136.212/users/auth',{headers:{accesstoken:localStorage.getItem('accessToken')}}).then((res)=>{
          if(res.data.error){
              setAuthState({name:"",status:false,id:0});
              console.log(res.data.error);
          }else{
          setAuthState({email:res.data.user.email,id:res.data.user.id,status:true});}})
      },[option])
  function submit(event) {
    event.preventDefault();
    axios.post('https://82.180.136.212/users/login', {email,password}).then((res) => {
      if (res.data.error) {
          console.log(res.data.error);
      } else {
        console.log(res.data.token)
        localStorage.setItem('accessToken', res.data.token);
        setAuthState({ email: res.data.user.email, id: res.data.user.id, status: true });
      }
    });
  }
  function submitU(event) {
    event.preventDefault();
    axios.post('https://82.180.136.212/users/UpdateAdmin', {emailU,passwordU},{headers:{accesstoken:localStorage.getItem('accessToken')}}).then((res) => {
      if (res.data.error) {
          console.log(res.data.error);
      } else {
        localStorage.removeItem('accessToken')
      }
    });
  }
  function showUpdatform(){
    formU.current.style.display='flex';
  }
  function Hidform(){
    formU.current.style.display='none';
    mess.current.style.display='flex';
  }
  function Hidmess(){
    mess.current.style.display='none';
    setOption(!option);
  }
  
  return (
    <AuthContext.Consumer >
      {({authState})=>(
    <div className='flex flex-col items-center justify-center h-[100vh] md:gap-10 gap-4'>
      <form className='border-4 flex border-black  text-[0.7rem] md:text-[1rem] md:w-[40vw] w-[80%] flex-col bg-myblue p-4 md:p-8 items-center justify-center lg:gap-8 md:gap-4 gap-2' method='post' onSubmit={submit}>
        <h2 className='md:text-[1.5rem] text-[1.1rem] text-bgsecond bg-bcolor border-2 border-black p-2'>log in</h2>
        <label>email</label><input type='text'  className='w-[80%] p-2' value={email}  name='email' onChange={(e)=>{setEmail(e.target.value)}} />
        <label >password</label><input type='password'  className='w-[80%] p-2' value={password}   name='password' onChange={(e)=>{setPassword(e.target.value)}} />
        <button type='submit' className='bg-bgsecond px-3 py-2'>submit</button>
      </form>
      <div>{(authState.status)?(<span class=" text-black bg-myblue border border-1 p-[2px] lg:text-[1.2rem] text-[0.6rem] cursor-pointer" onClick={()=>{localStorage.removeItem('accessToken');setOption(!option)}}>deconnecter</span>):(<></>)}</div>
      <div>{(authState.status)?(<span class="fas fa-cog text-[3rem] cursor-pointer" onClick={showUpdatform}></span>):(<></>)}</div>
      <form ref={formU} className=' flex-col hidden bg-bgsecond border-4 border-myblue p-4 md:p-8 items-center h-[60vh] justify-center lg:gap-8 md:gap-4 gap-2' method='post' onSubmit={submitU}>
      <h2 className='md:text-[1.5rem] text-[1.1rem] text-myblue bg-bcolor border-2 border-black p-2'>update account</h2>
        <label >update email</label><input className='w-[80%] p-2' type='text' value={emailU}  name='email' onChange={(e)=>{setEmailU(e.target.value)}} />
        <label > update password</label><input  className='w-[80%] p-2' type='password' value={passwordU}   name='password' onChange={(e)=>{setPasswordU(e.target.value)}} />
        <button type='submit' className='bg-myblue px-3 py-2' onClick={Hidform}>update</button>
      </form>
      <div ref={mess} onClick={Hidmess} className='absolute top-[50%] left-[50%] text-[0.7rem] md:text-[1rem] md:w-[40vw] w-[80%] translate-x-[-50%] translate-y-[-50%] bg-bgsecond p-4 h-[30vh] hidden items-center border-2 border-bgsecond'>
        you update your account succefully
      </div>
    </div>
      )}
    </AuthContext.Consumer>
    
  )
}

export default Admin
