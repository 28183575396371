import { motion } from 'framer-motion';

const MyIcon = () => {
  // Extracted path data from the SVG
  const pathData = "M240.62,330.89H90.27C40.42,330.89,0,290.48,0,240.62V90.27C0,40.42,40.42,0,90.27,0h150.35c49.86,0,90.27,40.42,90.27,90.27v150.35C330.89,290.48,290.48,330.89,240.62,330.89z";

  return (
    <motion.svg transition={{when:"afterChildren"}} viewBox="0 0 330.89 330.89" className='h-[50vmin] w-[50vmin] m-auto'>
      {/* Use motion.path component with extracted path data */}
     
      <g>
      {/* Use motion.path component with extracted path data */}
      
	<g>
		<motion.path initial={{pathLength:0,fillOpacity:0}}
       animate={{pathLength:1,fillOpacity:1}}
       transition={{duration:2}}  strokeWidth="0"
        stroke="#fff"
        fill={'#121EE4'}
        strokeLinecap="round"
        strokeLinejoin="round" d="M240.62,330.89H90.27C40.42,330.89,0,290.48,0,240.62V90.27C0,40.42,40.42,0,90.27,0h150.35
			c49.86,0,90.27,40.42,90.27,90.27v150.35C330.89,290.48,290.48,330.89,240.62,330.89z"/>
	</g>
	<motion.path initial={{pathLength:0,fillOpacity:0}}
       animate={{pathLength:1,fillOpacity:1}}
       transition={{duration:2}}  strokeWidth="10"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={'#Fff'}
         d="M282.9,201.89c-0.79-0.83-1.57-1.65-2.33-2.45c-25.55,15.91-50.23,15.42-63.25-1.07
		c5.78-10.15,9.3-21.08,9.36-33c0.06-12.11-3.69-23.17-9.73-34.02c20.72-16.25,41.25-15.84,62.16-1.1c1.22-1.39,2.37-2.7,3.48-3.96
		c1.71-1.95,1.44-4.93-0.59-6.54c-18.92-15.07-50.25-13.62-71.66,2.57c-0.2-0.05-0.44-0.03-0.58-0.15
		c-1.37-1.16-2.71-2.36-4.07-3.53c-16.35-14.11-35.21-18.07-56.13-14.12c-4.02,0.76-5.17,2.51-3.45,6.2
		c0.73,1.57,1.37,3.19,2.07,4.85c2.43-0.43,4.4-0.76,6.36-1.14c23.54-4.51,47.63,8.28,57.03,30.28
		c9.77,22.86,2.54,48.33-17.85,62.91c-19.03,13.6-46.59,12.16-63.9-3.72c-2.2-2.02-3.86-5.66-4.04-8.66
		c-0.76-13-0.65-26.06-1.41-39.06c-0.15-2.59-2.33-6.6-4.31-7.11c-2.14-0.56-5.67,1.69-7.65,3.66c-2.74,2.75-4.6,6.38-7.25,10.23
		c-1.61-2.19-2.81-3.7-3.88-5.31c-8.11-12.17-16.17-24.37-24.31-36.52c-4.58-6.83-7.65-7.06-13.18-1.17
		C45.29,139.66,40.9,170.7,53.18,195c3.73,7.38,8.47,13.87,14.65,19.41c3.29,2.95,8.42,2.37,11.03-1.19
		c0.49-0.68,0.99-1.36,1.5-2.05c-12.61-10.25-20.75-22.52-22.8-38.24c-2.05-15.75,1.6-29.91,12.09-42.92
		c1.88,2.67,3.29,4.58,4.6,6.56c8.22,12.34,16.35,24.74,24.68,37c3.81,5.62,7.65,5.64,11.98,0.46c0.62-0.74,1.25-1.49,1.87-2.24
		c1.12,0.41,1.14,0.41,2.26,0.82c-0.02,8.73-0.36,17.48,0.19,26.17c0.22,3.44,1.68,7.67,4.08,9.98
		c18.62,17.96,40.65,23.77,65.19,15.11c9.38-3.31,17.68-9.66,26.99-14.92c19.97,17.24,54.65,17.18,71.19,1.82
		C285.21,208.41,285.29,204.4,282.9,201.89z"/>
</g>
    </motion.svg>
  );
};

export default MyIcon;
