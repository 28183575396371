import React,{useState,useEffect} from 'react'
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
import LangagueChange from './LangagueChange';

function Privacypolicyfr() {
  const navigate=useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate a 2-second loading time
  }, []);
  return (
    <div>
                

    
      <div className=' bg-bgsecond min-h-[100vh] font-Montserrat text-myblue  lg:pt-10 md:pt-4 pt-3 '>
        <header className='flex justify-between lg:px-32 md:px-16 px-8'>
          <i onClick={()=>navigate('/')} className='cursor-pointer'>
            <img src={require('../img/Group.png')} alt='icon' className='lg:w-[4rem] md:w-[2.5rem] w-[1.5rem]'/>
          </i>
          <span className='relative'>
            <i class="fa-solid fa-bars lg:text-[3rem] md:text-[2rem] text-[1rem] cursor-pointer  text-myblue" onClick={()=>{
              document.querySelector('.fa-bars').style.display='none';  
              document.querySelector('.fa-x').style.display='block';  
              document.querySelector('.nav').style.display='flex';  
            }}></i>
            <i class="fa-solid fa-x lg:text-[2.4rem] md:text-[1.7rem] text-[0.8rem] hidden cursor-pointer  text-myblue" onClick={()=>{
              document.querySelector('.fa-bars').style.display='block';  
              document.querySelector('.fa-x').style.display='none';  
              document.querySelector('.nav').style.display='none';  
            }}></i>
            <ul className='bg-bgsecond nav hidden z-1000 text-myblue lists  flex-col rounded-md gap-2 absolute shadow-md lg:text-[1.4rem] md:text-[0.8rem] text-[0.6rem] lg:right-[2rem] right-[1.5rem] lg:w-[12vw] w-[30vw]'>
              <li className=' px-[5px] py-[1.5px] font-semibold border-b'><a href='/' className='w-full block'>accueil</a></li>
              <li className=' px-[5px] py-[1.5px]  font-semibold border-b cursor-pointer' ><a href='/aboutus' className='w-full block'>à propos de nous</a></li>
            </ul>
          </span>
        </header>
        <div className=' lg:mx-32 md:mx-16 mx-8 lg:mt-10 md:mt-[20px] mt-[10px] bg-myblue text-white rounded-md lg:text-[1.2rem] md:text-[1rem] text-[0.8rem] lg:px-4 md:px-2 px-[16px] w-fit p-[3px]'>politique de confidentialité</div>
        <section className='lg:px-32 md:px-16 px-8 flex flex-col lg:gap-8 md:gap-4 gap-[8px]  lg:my-10 my-[10px]'>
          <ul className='list-disc	lg:text-[1.2rem] md:text-[0.8rem] text-[0.6rem]'>
            <li>
                <h1 className='font-semibold'>Collecte d'informations personnelles</h1>
                <p>Nous collectons des informations personnelles auprès de vous lorsque vous effectuez une réservation, ouvrez un compte ou utilisez nos services. Ces informations peuvent inclure votre nom, adresse, numéro de téléphone, adresse e-mail, numéro de permis de conduire, informations de carte de crédit et autres informations d'identification. Nous pouvons également collecter des informations sur votre historique de location, y compris le type de véhicule que vous avez loué, les dates et heures de votre location et vos informations de conduite.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Utilisation des informations personnelles</h1>
                <p>Nous utilisons vos informations personnelles pour vous fournir nos services, y compris le traitement de vos réservations, la facturation de vos locations et la fourniture de service client. Nous pouvons également utiliser vos informations pour vous envoyer des communications marketing, telles que des offres et des réductions. Nous ne vendrons ni ne louerons vos informations personnelles à des tiers.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Partage des informations personnelles</h1>
                <p>Nous pouvons partager vos informations personnelles avec nos sociétés affiliées, nos franchisés et nos entrepreneurs indépendants qui nous fournissent des services. Nous pouvons également partager vos informations avec des fournisseurs tiers qui nous aident à exploiter notre entreprise, tels que des processeurs de cartes de crédit, des compagnies d'assurance et des fournisseurs d'assistance routière. Nous ne partagerons vos informations avec ces tiers que dans la mesure nécessaire pour vous fournir nos services ou pour nous conformer aux exigences légales.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Vos droits</h1>
                <p>Vous avez le droit d'accéder, de corriger ou de supprimer vos informations personnelles. Vous avez également le droit de vous opposer au traitement de vos informations personnelles. Vous pouvez exercer ces droits en nous contactant à l'adresse ou au numéro de téléphone fourni ci-dessous.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Sécurité des données</h1>
                <p>Nous prenons des mesures techniques et organisationnelles appropriées pour protéger vos informations personnelles contre tout accès, utilisation, divulgation, altération ou destruction non autorisés. Nous utilisons une variété de technologies et de procédures de sécurité pour vous aider à protéger vos informations, telles que des pare-feu, le chiffrement des données et des contrôles d'accès.</p>
            </li>
            <li>
                <h1 className='font-semibold'>Modification de cette politique de confidentialité</h1>
                <p>Nous pouvons mettre à jour cette politique de confidentialité de temps à autre. Nous publierons tous les changements sur cette page. Nous vous encourageons à consulter périodiquement cette politique de confidentialité pour rester informé de la manière dont nous protégeons vos informations personnelles.</p>
            </li>
            <li>        
                <h1 className='font-semibold'>Nous contacter</h1>
                <p>Si vous avez des questions sur cette politique de confidentialité, veuillez nous contacter à :</p>
                <div className='flex flex-col lg:gap-2  gap-1 lg:pl-4 md:pl-2 pl-[10px]'>
                    <p className='uppercase'>My drive car</p>
                    <a href='mailto:Contact@mdcrentalcars.com'  rel="nofollow noopener noreferrer" className='underline' target='_blank'>Contact@mdcrentalcars.com</a>
                    <a href='https://goo.gl/maps/MNhaT5xYumiF6fzR8' rel="nofollow noopener noreferrer" className='underline' target='_blank'>Marrakech-Morocco</a>
                    <a href='tel:+212665859587' className='underline' rel="nofollow noopener noreferrer" target='_blank'>+212665859587</a>
                </div>
            </li>
          </ul>
        </section>
        <Footer px={32}/>
      </div>
  
</div>

)
  
}

export default Privacypolicyfr
