import category_a_1 from '../img/category-1-a.png'
import category_a_2 from '../img/category-2-a.png'
import category_a_3 from '../img/category-3-a.png'
import category_b_1 from '../img/category-1-b.png'
import category_b_2 from '../img/category-2-b.png'
import category_b_3 from '../img/category-3-b.png'
import category_b_4 from '../img/category-4-b.png'
import category_c_1 from '../img/category-1-c.png'
import category_c_2 from '../img/category-2-c.png'
import category_c_3 from '../img/category-3-c.png'
import category_d_1 from '../img/category-1-d.png'
import category_d_2 from '../img/category-2-d.png'
import category_d_3 from '../img/category-3-d.png'
import category_e_1 from '../img/category-e-1.png'
import category_e_2 from '../img/category-e-2.png'
export const data = [
  {
    id: 1,
    title: "Hyundai I10",
    image: category_a_1,
    front: true,
    category: "a",
    prix: 35,
  },
  {
    id: 2,
    title: "Kia picanto",
    image: category_a_2,
    front: true,
    category: "a",
    prix: 35,
  },
  {
    id: 3,
    title: "Toyota yaris",
    image: category_a_3,
    front: true,
    category: "a",
    prix: 35,
  },

  {
    id: 4,
    title: "Citroen c3",
    image: category_b_1,
    front: true,
    category: "b",
  },
  {
    id: 5,
    title: "Dacia logan",
    image: category_b_2,
    front: true,
    category: "b",
    prix: 40,
  },
  {
    id: 6,
    title: "Renault clio5",
    image: category_b_3,
    front: true,
    prix: 40,
    category: "b",
  },
  {
    id: 7,
    title: "Peugeot 208",
    image: category_b_4,
    front: true,
    category: "b",
    prix: 40,
  },

  {
    id: 8,
    title: "Renault megan",
    image: category_c_1,
    front: true,
    category: "c",
    prix: 45,
  },
  {
    id: 9,
    title: "Dacia duster",
    image: category_c_2,
    front: true,
    category: "c",
    prix: 45,
  },
  {
    id: 10,
    title: "Dacia lodgy",
    image: category_c_3,
    front: true,
    category: "c",
    prix: 45,
  },

  {
    id: 11,
    title: "Jeep renegade",
    image: category_d_1,
    front: true,
    category: "d",
    prix: 70,
  },
  {
    id: 12,
    title: "Hyundai tucson",
    image: category_d_2,
    front: true,
    category: "d",
    prix: 70,
  },
  {
    id: 13,
    title: "Volkswagen troc",
    image: category_d_3,
    front: true,
    category: "d",
    prix: 70,
  },
  {
    id: 14,
    title: "Range rover evoque",
    image: category_e_1,
    front: true,
    category: "e",
    prix: 120,
  },
  {
    id: 15,
    title: "Volkswagen touareg",
    image: category_e_2,
    front: true,
    category: "e",
    prix: 100,
  },
];